import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import {
  getAreaList,
  getDesignationList,
  getMCDeptList,
  getPlaces4mCentreId,
  getCentre4mAreaId,
  printDirDeptData,
} from "../../../services/HTTPService";
import { useNavigate } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import swal from "sweetalert";
import { logout } from "../../../services/AuthService";
import "./printDirDeptWise.css";
//
const PrintLists = () => {
  const current = new Date();
  const printDateTime = current.toLocaleString("en-US", {
    // day: "numeric",
    // month: "short",
    // year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  });
  const printDate = `${current.getDate()}/${
    current.getMonth() + 1
  }/${current.getFullYear()} ${printDateTime}`;

  const navigate = useNavigate();
  const componentRef = useRef();
  const defaultPrintChoice = {
    area: "",
    areaid: "",
    place: "",
    placeid: "",
    centre: "",
    centreid: "",
    gender: "",
    designation: "",
    designationid: "",
    department: "",
    departmentid: "",
    sewaarea: "",
    sewaareaid: "",
    userstatus: "",
    order1: "sewaarea",
    order2: "department",
    order3: "area",
    order4: "name",
  };
  const defaultReportPayload = {
    name: "",
    contact1: "",
    contact2: "",
    gender: "",
    designation: "",
    department: "",
    areafrom: "",
    sewaarea: "",
    place: "",
    userstatus: "",
  };
  const [printChoice, setPrintChoice] = useState(defaultPrintChoice);
  const [report, setReport] = useState(defaultReportPayload);
  //
  const [area, setArea] = useState([]);
  const [place, setPlace] = useState([]);
  const [centre, setCentre] = useState([]);
  const [designation, setDesignation] = useState([]);
  const [department, setDepartment] = useState([]);
  const [sewaArea, setSewaArea] = useState([]);
  //
  const [printArea, setPrintArea] = useState("");
  const [printPlace, setPrintPlace] = useState("");
  const [printCentre, setPrintCentre] = useState("");
  const [printGender, setPrintGender] = useState("");
  const [printDesig, setPrintDesig] = useState("");
  const [printDept, setPrintDept] = useState("");
  const [printUserStatus, setPrintUserStatus] = useState("");
  //
  const getAreas = () => {
    getAreaList().then((res) => {
      if (res.data.length > 0) {
        setArea(res.data);
      } else {
        toast.error("No record found");
      }
    });
  };
  const getDesignation = () => {
    getDesignationList().then((response) => {
      if (response.status === 200) {
        setDesignation(response.data);
      } else {
        navigate(`/dashboard`);
        console.log(response.data);
        toast.error(response.data.msg);
      }
    });
  };
  const getDepartment = () => {
    getMCDeptList().then((response) => {
      if (response.status === 200) {
        setDepartment(response.data);
      } else {
        navigate(`/dashboard`);
        console.log(response.data);
        toast.error(response.data.msg);
      }
    });
  };
  //
  const onChangeArea = (val) => {
    setPrintChoice((prevState) => ({
      ...prevState,
      ["placeid"]: "",
    }));
    setPrintChoice((prevState) => ({
      ...prevState,
      ["place"]: "",
    }));
    setPrintChoice((prevState) => ({
      ...prevState,
      ["centreid"]: "",
    }));
    setPrintChoice((prevState) => ({
      ...prevState,
      ["centre"]: "",
    }));
    getCentre4mAreaId({ areaid: val }).then((response) => {
      if (response.status === 200) {
        if (response.data.status === "success") {
          setCentre(response.data.data);
        } else {
          toast.error(response.data.msg);
        }
      } else {
        console.log("error");
        navigate(`/dashboard`);
      }
    });
  };
  const onChangeCentre = (val) => {
    setPrintChoice((prevState) => ({
      ...prevState,
      ["placeid"]: "",
    }));
    setPrintChoice((prevState) => ({
      ...prevState,
      ["place"]: "",
    }));
    getPlaces4mCentreId({ centreid: val }).then((response) => {
      if (response.status === 200) {
        if (response.data.status === "success") {
          setPlace(response.data.data);
        } else {
          toast.error(response.data.msg);
        }
      } else {
        console.log("error");
        navigate(`/dashboard`);
      }
    });
  };
  //
  useEffect(() => {
    getAreas();
    getDesignation();
    getDepartment();
  }, []);
  //
  const updateFieldsData = (e) => {
    // console.log(printChoice);
    const { name, value } = e.target;
    const { options, selectedIndex } = e.target;
    let printOption = options[selectedIndex].innerHTML;
    //
    switch (name) {
      case "areaid":
        setPrintChoice({ ...printChoice, [name]: value });
        if (value !== "") {
          onChangeArea(value);
          setPrintChoice((prevState) => ({
            ...prevState,
            ["area"]: printOption,
          }));
        }
        break;
      case "centreid":
        setPrintChoice({ ...printChoice, [name]: value });
        if (value !== "") {
          onChangeCentre(value);
          setPrintChoice((prevState) => ({
            ...prevState,
            ["centre"]: printOption,
          }));
        }
        break;
      case "placeid":
        setPrintChoice({ ...printChoice, [name]: value });
        if (value !== "") {
          setPrintChoice((prevState) => ({
            ...prevState,
            ["place"]: printOption,
          }));
        }
        break;
      case "designationid":
        setPrintChoice({ ...printChoice, [name]: value });
        if (value !== "") {
          setPrintChoice((prevState) => ({
            ...prevState,
            ["designation"]: printOption,
          }));
        }
        break;
      case "departmentid":
        setPrintChoice({ ...printChoice, [name]: value });
        if (value !== "") {
          setPrintChoice((prevState) => ({
            ...prevState,
            ["department"]: printOption,
          }));
        }
        break;
      case "sewaareaid":
        setPrintChoice({ ...printChoice, [name]: value });
        if (value !== "") {
          setPrintChoice((prevState) => ({
            ...prevState,
            ["sewaarea"]: printOption,
          }));
        }
        break;
      default:
        setPrintChoice({ ...printChoice, [name]: value });
        break;
    }
    // if (value !== "") {
    //   checkValidity(value);
    // }
  };
  //
  const checkValidation = () => {
    assignPrintVariable();
    if (printChoice.centreid !== "" && printChoice.placeid !== "") {
      setPrintChoice({ ...printChoice, ["placeid"]: "" });
      setPrintChoice((prevState) => ({
        ...prevState,
        ["place"]: "",
      }));
    }
    //
    // if (printChoice.areaid === "") {
    //   toast("Please select Area");
    // } else {
    printDirDeptData(printChoice)
      .then((response) => {
        if (response.data.status === "success") {
          console.log(response.data.qry);
          toast.success(response.data.msg);
          setReport(response.data.data);
        } else if (response.data.status === "error") {
          console.log(response.data);
          swal("Error.?", response.data.msg, "error");
        } else if (response.data.status === "logout") {
          logout();
          navigate("/login");
        } else {
          toast.error(
            "Data Not Found, Something went happned. " + response.data.status
          );
        }
      })
      .catch((res) => {
        navigate("/dashboard");
        toast.error("API Error, Something went happned");
      });
    // }
  };
  const resetFilters = () => {
    setPrintChoice({ ...defaultPrintChoice });
  };
  const assignPrintVariable = () => {
    setPrintUserStatus(printChoice.userstatus);
    setPrintGender(printChoice.gender);
    setPrintDesig(printChoice.designation);
    setPrintDesig(printChoice.department);
    setPrintArea(printChoice.area);
    setPrintPlace(printChoice.place);
    setPrintCentre(printChoice.centre);
  };
  //
  const printReport = useReactToPrint({
    content: () => componentRef.current,
  });
  //
  const reportData = () => {
    let totalPage = Math.round(report.length / 42);
    if (totalPage < report.length / 42) {
      totalPage = totalPage + 1;
    }
    return (
      <>
        {report.length > 0 ? (
          <div>
            <div
              className="col-12 report-page table-responsive"
              ref={componentRef}
            >
              <table className="printTable">
                <thead>
                  <tr>
                    <td
                      colSpan={2}
                      style={{
                        fontSize: "13px",
                        textAlign: "left",
                        paddingLeft: "10px",
                        borderLeft: "1px solid black",
                        borderTop: "1px solid black",
                        borderRight: "0px solid black",
                      }}
                    >
                      <span>
                        Status :{" "}
                        {printUserStatus === "1"
                          ? "Active"
                          : printUserStatus === "0"
                          ? "Not Active"
                          : "All"}
                      </span>
                      <br />
                      <span>
                        Place : {""}
                        {printPlace !== ""
                          ? printPlace
                          : printCentre && !printPlace
                          ? printCentre + " (C): ALL"
                          : printArea + " (Area): All"}
                      </span>
                      <br />
                      <span>
                        Designation : {""}
                        {printDesig ? printDesig : "All"}
                      </span>
                    </td>
                    <td
                      colSpan={4}
                      style={{
                        textAlign: "center",
                        borderTop: "1px solid black",
                        borderRight: "0px solid black",
                      }}
                    >
                      <font size="4">
                        <strong>DIRECTORY WITH DEPARTMENT</strong>
                      </font>
                      <br />
                      <font size="3">
                        <strong>[ TOTAL : {report.length} ]</strong>
                      </font>
                      <br />
                    </td>
                    <td
                      colSpan={2}
                      style={{
                        fontSize: "13px",
                        textAlign: "right",
                        paddingRight: "10px",
                        borderTop: "1px solid black",
                        borderRight: "1px solid black",
                      }}
                    >
                      <span>Total Page : {totalPage}</span>
                      <br />
                      <span>
                        Gender :{" "}
                        {printGender === "M"
                          ? "Male"
                          : printGender === "F"
                          ? "Female"
                          : printGender === "O"
                          ? "Other"
                          : "All"}
                      </span>
                      <br />
                      <span>PrintDate :</span> {printDate}
                    </td>
                  </tr>
                  <tr className="border-below">
                    <th className="report-heading" style={{ width: "1%" }}>
                      SN.
                    </th>
                    <th className="report-heading" style={{ width: "25%" }}>
                      Name
                    </th>
                    <th className="report-heading" style={{ width: "7%" }}>
                      Contact1
                    </th>
                    <th className="report-heading" style={{ width: "1%" }}>
                      M/F
                    </th>
                    <th className="report-heading" style={{ width: "18%" }}>
                      Designation
                    </th>
                    <th className="report-heading" style={{ width: "18%" }}>
                      Department
                    </th>
                    <th className="report-heading" style={{ width: "15%" }}>
                      Area{" "}
                    </th>
                    <th className="report-heading" style={{ width: "15%" }}>
                      SewaArea
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {report.map((data, key) => {
                    return (
                      <tr key={key}>
                        <td className="report-data text-center">{key + 1}</td>
                        <td className="report-data">{data.name}</td>
                        <td className="report-data">{data.contact1}</td>
                        <td className="report-data text-center">
                          {data.gender}
                        </td>
                        <td className="report-data">{data.designation}</td>
                        <td className="report-data">{data.department}</td>
                        <td className="report-data">{data.area}</td>
                        <td className="report-data">{data.sewaarea}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        ) : null}
      </>
    );
  };
  //
  return (
    <div className="app-content pt-3 p-md-3 p-lg-4">
      <div className="container-xl">
        <h1 className="app-page-title">Print Directory</h1>
        <div className="row">
          <div className="col-12 app-card-settings app-card pt-3 pb-3">
            <div>
              <div className="row mb-4">
                <div className="col-md-3 col-12">
                  <label className="form-label" htmlFor="">
                    Sewadar From Area
                  </label>
                  <select
                    className="form-select"
                    name="areaid"
                    value={printChoice.areaid}
                    onChange={updateFieldsData}
                  >
                    <option value="">Select Area</option>
                    {area.map((val, key) => {
                      return (
                        <option key={key} value={val.id}>
                          {val.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="col-md-3 col-12">
                  <label className="form-label" htmlFor="">
                    Attached Centre
                  </label>
                  <select
                    className="form-select"
                    name="centreid"
                    value={printChoice.centreid}
                    onChange={updateFieldsData}
                  >
                    <option value="">Select Center</option>
                    {centre.map((val, key) => {
                      return (
                        <option key={key} value={val.id}>
                          {val.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="col-md-3 col-12">
                  <label className="form-label" htmlFor="">
                    C/SC/SP
                  </label>
                  <select
                    className="form-select"
                    name="placeid"
                    value={printChoice.placeid}
                    onChange={updateFieldsData}
                  >
                    <option value="">Select Place</option>
                    {place.map((val, key) => {
                      return (
                        <option key={key} value={val.id}>
                          {val.name + " (" + val.placetype + ")"}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="col-md-3 col-12">
                  <label className="form-label" htmlFor="">
                    Gender
                  </label>
                  <select
                    className="form-select"
                    name="gender"
                    value={printChoice.gender}
                    onChange={updateFieldsData}
                  >
                    <option value="">Select Gender</option>
                    <option value="M">Male</option>
                    <option value="F">Female</option>
                    <option value="O">Other</option>
                  </select>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-md-3 col-12">
                  <label className="form-label" htmlFor="">
                    Designation
                  </label>
                  <select
                    className="form-select"
                    name="designationid"
                    value={printChoice.designationid}
                    onChange={updateFieldsData}
                  >
                    <option value="">Select Designation</option>
                    {designation.map((val, key) => {
                      return (
                        <option key={key} value={val.id}>
                          {val.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="col-md-3 col-12">
                  <label className="form-label" htmlFor="">
                    Department
                  </label>
                  <select
                    className="form-select"
                    name="departmentid"
                    value={printChoice.departmentid}
                    onChange={updateFieldsData}
                  >
                    <option value="">Select Department</option>
                    {department.map((val, key) => {
                      return (
                        <option key={key} value={val.id}>
                          {val.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="col-md-3 col-12">
                  <label className="form-label" htmlFor="">
                    Area (Where is Sewa) <span style={{ color: "red" }}></span>
                  </label>
                  <select
                    className="form-select"
                    name="sewaareaid"
                    value={printChoice.sewaareaid}
                    onChange={updateFieldsData}
                  >
                    <option value="">All Areas</option>
                    {area.map((val, key) => {
                      return (
                        <option key={key} value={val.id}>
                          {val.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="col-md-3 col-12">
                  <label className="form-label" htmlFor="">
                    Status
                  </label>
                  <select
                    className="form-select"
                    name="userstatus"
                    value={printChoice.userstatus}
                    onChange={updateFieldsData}
                  >
                    <option value="">Select Status</option>
                    <option value="1">Active</option>
                    <option value="0">Not Active</option>
                  </select>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-md-3 col-12">
                  <label className="form-label" htmlFor="">
                    1st Order
                  </label>
                  <select
                    className="form-select"
                    name="order1"
                    value={printChoice.order1}
                    onChange={updateFieldsData}
                  >
                    <option value="sewaarea">SewaArea</option>
                    <option value="area">AreaFromSewadar</option>
                    <option value="place">Place</option>
                    <option value="department">Department</option>
                    <option value="designation">Designation</option>
                    <option value="gender">Gender</option>
                    <option value="name">Name</option>
                  </select>
                </div>
                <div className="col-md-3 col-12">
                  <label className="form-label" htmlFor="">
                    2st Order
                  </label>
                  <select
                    className="form-select"
                    name="order2"
                    value={printChoice.order2}
                    onChange={updateFieldsData}
                  >
                    <option value="department">Department</option>
                    <option value="area">AreaFromSewadar</option>
                    <option value="place">Place</option>
                    <option value="designation">Designation</option>
                    <option value="gender">Gender</option>
                    <option value="name">Name</option>
                    <option value="sewaarea">SewaArea</option>
                  </select>
                </div>
                <div className="col-md-3 col-12">
                  <label className="form-label" htmlFor="">
                    3rd Order
                  </label>
                  <select
                    className="form-select"
                    name="order3"
                    value={printChoice.order3}
                    onChange={updateFieldsData}
                  >
                    <option value="area">AreaFromSewadar</option>
                    <option value="place">Place</option>
                    <option value="designation">Designation</option>
                    <option value="gender">Gender</option>
                    <option value="name">Name</option>
                    <option value="department">Department</option>
                    <option value="sewaarea">SewaArea</option>
                  </select>
                </div>
                <div className="col-md-3 col-12">
                  <label className="form-label" htmlFor="">
                    4th Order
                  </label>
                  <select
                    className="form-select"
                    name="order4"
                    value={printChoice.order4}
                    onChange={updateFieldsData}
                  >
                    <option value="name">Name</option>
                    <option value="place">Place</option>
                    <option value="designation">Designation</option>
                    <option value="department">Department</option>
                    <option value="gender">Gender</option>
                    <option value="area">AreaFromSewadar</option>
                    <option value="sewaarea">SewaArea</option>
                  </select>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-md-6 col-12">
                  <div className="col-md-4 col-12" style={{ float: "left" }}>
                    <button
                      className="btn app-btn-primary"
                      style={{ width: "100%" }}
                      onClick={checkValidation}
                    >
                      <i className="fa fa-paper-plane" aria-hidden="true"></i>{" "}
                      Show Report
                    </button>
                  </div>
                  <div className="col-md-4 col-12" style={{ float: "right" }}>
                    <button
                      className="btn app-btn-primary"
                      style={{ width: "100%" }}
                      onClick={resetFilters}
                    >
                      <i className="fa fa-fire" aria-hidden="true"></i> Reset
                      Filter
                    </button>
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  {report.length > 0 ? (
                    <div className="col-md-4 col-12" style={{ float: "right" }}>
                      <button
                        className="btn app-btn-primary"
                        style={{ width: "100%" }}
                        onClick={printReport}
                      >
                        <i className="fa fa-print" aria-hidden="true"></i> Print
                        Report
                      </button>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div
              className="row"
              style={{ height: "10px", backgroundColor: "#15a362" }}
            ></div>
            {reportData()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrintLists;
