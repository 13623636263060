import React, { useState, useEffect } from "react";
import { validations } from "../../../services/ValidationService";

export default function DialogDeptDetails(props) {
  const defaultPayload = {
    id: "",
    name: "",
  };
  const [payload, setPayload] = useState(defaultPayload);
  const [editMode, setEditMode] = useState(false);
  const toggleVisible = props.toggleVisible;
  //
  useEffect(() => {
    setPayload(props.payload);
    // console.log("Props:", props.payload);
  }, [props.payload]);
  useEffect(() => {
    setEditMode(props.editmode);
    // console.log("Edit Mode:", props.editmode);
  }, [props.editmode]);
  //
  const cancelHandler = () => {
    toggleVisible();
  };
  //
  const updateFieldsData = (e) => {
    const { name, value } = e.target;
    setPayload({ ...payload, [name]: value });
  };
  const trigger = () => {
    props.onSubmit(payload);
  };
  //
  const view = () => {
    return (
      <>
        <div className="dialog-container">
          <div className="">
            <h4 style={{ textAlign: "center" }}>
              {editMode ? "Edit Department Details" : "Add New Department"}
            </h4>
            <div className="mt-4 mb-4">
              <label className="form-label" htmlFor="">
                Department Name <span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="text"
                name="name"
                value={payload.name}
                className="form-control"
                placeholder="Name"
                onChange={updateFieldsData}
              ></input>
            </div>
          </div>
          <div className="button-container">
            <button
              className="btn app-btn-secondary"
              onClick={() => cancelHandler()}
            >
              Cancel
            </button>
            <button className="btn app-btn-primary" onClick={trigger}>
              {editMode ? "Update" : "Save"}
            </button>
          </div>
        </div>
        <div className="confirm-bg"></div>
      </>
    );
  };

  return <div>{props.isVisible ? view() : ""}</div>;
}
