import React, { useState, useEffect, useRef } from "react";
import { getRole, getAreaId } from "../../../services/AuthService";
import { toast } from "react-toastify";
import {
  getAreaList,
  printApprovedDevices,
} from "../../../services/HTTPService";
import { useNavigate } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import swal from "sweetalert";
import { logout } from "../../../services/AuthService";
import "./printDir.css";
//
const PrintApprovedDevices = () => {
  const current = new Date();
  const printDateTime = current.toLocaleString("en-US", {
    // day: "numeric",
    // month: "short",
    // year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  });
  const printDate = `${current.getDate()}/${
    current.getMonth() + 1
  }/${current.getFullYear()} ${printDateTime}`;

  const navigate = useNavigate();
  const componentRef = useRef();
  const defaultPrintChoice = {
    area: "",
    areaid: "",
    // name: "",
    // contact: "",
    userstatus: "",
    asstatus: "",
    order1: "area",
    order2: "name",
    order3: "status",
    order4: "asbutton",
  };
  const defaultReportPayload = {
    deviceId: "",
    area: "",
    name: "",
    contact: "",
    userstatus: "",
    asstatus: "",
  };
  const [printChoice, setPrintChoice] = useState(defaultPrintChoice);
  const [report, setReport] = useState(defaultReportPayload);
  const [area, setArea] = useState([]);
  const [place, setPlace] = useState([]);
  //
  const [printArea, setPrintArea] = useState("");
  const [printUserStatus, setPrintUserStatus] = useState("");
  const [printASStatus, setPrintASStatus] = useState("");
  //
  const getAreas = () => {
    getAreaList().then((res) => {
      if (res.data.length > 0) {
        setArea(res.data);
      } else {
        toast.error("No record found");
      }
    });
  };
  useEffect(() => {
    getAreas();
  }, []);
  //
  const updateFieldsData = (e) => {
    const { name, value } = e.target;
    const { options, selectedIndex } = e.target;
    let printOption = options[selectedIndex].innerHTML;
    //
    switch (name) {
      case "areaid":
        setPrintChoice({ ...printChoice, [name]: value });
        if (value !== "") {
          setPrintChoice((prevState) => ({
            ...prevState,
            ["area"]: printOption,
          }));
        }
        break;
      default:
        setPrintChoice({ ...printChoice, [name]: value });
        break;
    }
  };
  //
  const checkValidation = () => {
    assignPrintVariable();
    if (printChoice.areaid === "") {
      toast("Please select Area");
    } else {
      printApprovedDevices(printChoice)
        .then((response) => {
          if (response.data.status === "success") {
            console.log(response.data.data);
            setReport(response.data.data);
            toast.success(response.data.msg);
          } else if (response.data.status === "error") {
            console.log(response.data);
            swal("Error.?", response.data.msg, "error");
          } else if (response.data.status === "logout") {
            logout();
            navigate("/login");
          } else {
            toast.error(
              "Data Not Found, Something went happned. " + response.data.status
            );
          }
        })
        .catch((res) => {
          navigate("/dashboard");
          toast.error("API Error, Something went happned");
        });
    }
  };
  const resetFilters = () => {
    setPrintChoice({ ...defaultPrintChoice });
  };
  const assignPrintVariable = () => {
    setPrintUserStatus(printChoice.userstatus);
    setPrintASStatus(printChoice.asstatus);
    setPrintArea(printChoice.area);
  };
  //
  const printReport = useReactToPrint({
    content: () => componentRef.current,
  });
  //
  const reportData = () => {
    let totalPage = Math.round(report.length / 45);
    if (totalPage < report.length / 45) {
      totalPage = totalPage + 1;
    }
    return (
      <>
        {report.length > 0 ? (
          <div>
            <div
              className="col-12 report-page table-responsive"
              ref={componentRef}
            >
              <table className="printTable">
                <thead>
                  <tr>
                    <td
                      colSpan={2}
                      style={{
                        fontSize: "13px",
                        textAlign: "left",
                        paddingLeft: "10px",
                        borderLeft: "1px solid black",
                        borderTop: "1px solid black",
                        borderRight: "0px solid black",
                      }}
                    >
                      <span>
                        Status :{" "}
                        {printUserStatus === "1"
                          ? "Active"
                          : printUserStatus === "0"
                          ? "Not Active"
                          : "All"}
                      </span>
                      <br />
                      <br />
                      {/* <span>
                        AS Status :{" "}
                        {printASStatus === "1"
                          ? "Yes"
                          : printASStatus === "0"
                          ? "No"
                          : "All"}
                      </span> */}
                    </td>
                    <td
                      colSpan={2}
                      style={{
                        textAlign: "center",
                        borderTop: "1px solid black",
                        borderRight: "0px solid black",
                      }}
                    >
                      <font size="3">
                        <strong>DIRECTORY APPROVED USERS </strong>
                      </font>
                      <br />
                      <font size="3">
                        <strong>[ TOTAL : {report.length} ]</strong>
                      </font>
                      <br />
                    </td>
                    <td
                      colSpan={3}
                      style={{
                        fontSize: "13px",
                        textAlign: "right",
                        paddingRight: "10px",
                        borderTop: "1px solid black",
                        borderRight: "1px solid black",
                      }}
                    >
                      <span>Total Page :{totalPage}</span>
                      <br />
                      <br />
                      <span>PrintDate :</span> {printDate}
                    </td>
                  </tr>
                  <tr className="border-below">
                    <th className="report-heading" style={{ width: "1%" }}>
                      SN.
                    </th>
                    <th className="report-heading" style={{ width: "25%" }}>
                      Name
                    </th>
                    <th className="report-heading" style={{ width: "20%" }}>
                      Contact
                    </th>
                    <th className="report-heading" style={{ width: "10%" }}>
                      Status
                    </th>
                    <th className="report-heading" style={{ width: "10%" }}>
                      AS Status
                    </th>
                    <th className="report-heading" style={{ width: "20%" }}>
                      Area
                    </th>
                    {getRole() == 1 && (
                      <th className="report-heading" style={{ width: "20%" }}>
                        Approved By
                      </th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {report.map((data, key) => {
                    return (
                      <tr key={key}>
                        <td className="report-data text-center">{key + 1}</td>
                        <td className="report-data">{data.name}</td>
                        <td className="report-data text-center">
                          {data.contact}
                        </td>
                        <td className="report-data text-center">
                          {data.userstatus == 1 ? "Active" : "Not Active"}
                        </td>
                        <td className="report-data text-center">
                          {data.asstatus == 1 ? "Yes" : "No"}
                          {/* {data.asstatus} */}
                        </td>
                        <td className="report-data text-center">{data.area}</td>
                        {getRole() == 1 && (
                          <td className="report-data text-center">
                            {data.approvedBy}
                          </td>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        ) : null}
      </>
    );
  };
  //
  return (
    <div className="app-content pt-3 p-md-3 p-lg-4">
      <div className="container-xl">
        <h1 className="app-page-title">Print Approved Devices</h1>
        <div className="row">
          <div className="col-12 app-card-settings app-card pt-3 pb-3">
            <div>
              <div className="row mb-4">
                <div className="col-md-3 col-12">
                  <label className="form-label" htmlFor="">
                    Area <span style={{ color: "red" }}>*</span>
                  </label>
                  <select
                    className="form-select"
                    name="areaid"
                    value={printChoice.areaid}
                    onChange={updateFieldsData}
                  >
                    <option value="">Select Area</option>
                    {area.map((val, key) => {
                      return (
                        // <option key={key} value={val.id}>
                        //   {val.name}
                        // </option>
                        <>
                          {getRole() <= 2 ? (
                            <option key={key} value={val.id}>
                              {val.name}
                            </option>
                          ) : getAreaId() === val.id ? (
                            <option key={key} value={val.id}>
                              {val.name}
                            </option>
                          ) : null}
                        </>
                      );
                    })}
                  </select>
                </div>
                <div className="col-md-3 col-12">
                  <label className="form-label" htmlFor="">
                    Status
                  </label>
                  <select
                    className="form-select"
                    name="userstatus"
                    value={printChoice.userstatus}
                    onChange={updateFieldsData}
                  >
                    <option value="">Select Status</option>
                    <option value="1">Active</option>
                    <option value="0">Not Active</option>
                  </select>
                </div>
                <div className="col-md-3 col-12">
                  <label className="form-label" htmlFor="">
                    AS Status
                  </label>
                  <select
                    className="form-select"
                    name="asstatus"
                    value={printChoice.asstatus}
                    onChange={updateFieldsData}
                  >
                    <option value="">Select Status</option>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                  </select>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-md-3 col-12">
                  <label className="form-label" htmlFor="">
                    1st Order
                  </label>
                  <select
                    className="form-select"
                    name="order1"
                    value={printChoice.order1}
                    onChange={updateFieldsData}
                  >
                    <option value="area">Area</option>
                    <option value="name">Name</option>
                    <option value="status">Status</option>
                    <option value="asbutton">AS Status</option>
                    <option value="approved_by">ApprovedBy</option>
                  </select>
                </div>
                <div className="col-md-3 col-12">
                  <label className="form-label" htmlFor="">
                    2st Order
                  </label>
                  <select
                    className="form-select"
                    name="order2"
                    value={printChoice.order2}
                    onChange={updateFieldsData}
                  >
                    <option value="name">Name</option>
                    <option value="area">Area</option>
                    <option value="status">Status</option>
                    <option value="asbutton">AS Status</option>
                    <option value="approved_by">ApprovedBy</option>
                  </select>
                </div>
                <div className="col-md-3 col-12">
                  <label className="form-label" htmlFor="">
                    3rd Order
                  </label>
                  <select
                    className="form-select"
                    name="order3"
                    value={printChoice.order3}
                    onChange={updateFieldsData}
                  >
                    <option value="status">Status</option>
                    <option value="area">Area</option>
                    <option value="name">Name</option>
                    <option value="asbutton">AS Status</option>
                    <option value="approved_by">ApprovedBy</option>
                  </select>
                </div>
                <div className="col-md-3 col-12">
                  <label className="form-label" htmlFor="">
                    4th Order
                  </label>
                  <select
                    className="form-select"
                    name="order4"
                    value={printChoice.order4}
                    onChange={updateFieldsData}
                  >
                    <option value="asbutton">AS Status</option>
                    <option value="area">Area</option>
                    <option value="name">Name</option>
                    <option value="status">Status</option>
                    <option value="approved_by">ApprovedBy</option>
                  </select>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-md-6 col-12">
                  <div className="col-md-4 col-12" style={{ float: "left" }}>
                    <button
                      className="btn app-btn-primary"
                      style={{ width: "100%" }}
                      onClick={checkValidation}
                    >
                      <i className="fa fa-paper-plane" aria-hidden="true"></i>{" "}
                      Show Report
                    </button>
                  </div>
                  <div className="col-md-4 col-12" style={{ float: "right" }}>
                    <button
                      className="btn app-btn-primary"
                      style={{ width: "100%" }}
                      onClick={resetFilters}
                    >
                      <i className="fa fa-fire" aria-hidden="true"></i> Reset
                      Filter
                    </button>
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  {report.length > 0 ? (
                    <div className="col-md-4 col-12" style={{ float: "right" }}>
                      <button
                        className="btn app-btn-primary"
                        style={{ width: "100%" }}
                        onClick={printReport}
                      >
                        <i className="fa fa-print" aria-hidden="true"></i> Print
                        Report
                      </button>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div
              className="row"
              style={{ height: "10px", backgroundColor: "#15a362" }}
            ></div>
            {reportData()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrintApprovedDevices;
