import React, { useState } from "react";
import { getRole } from "../../services/AuthService";
import {
  getDepartmentsData,
  addNewDepartment,
  updateDepartment,
} from "../../services/HTTPService";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { logout } from "../../services/AuthService";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import swal from "sweetalert";
import Datatable from "../../components/Shared/Datatable/Datatable";
import DialogDeptDetails from "../../components/Shared/DialogsBoxes/DialogDeptDetails";
import DialogDeleteData from "../../components/Shared/DialogsBoxes/DialogDeleteData";
//
const ViewDepartments = (props) => {
  const navigate = useNavigate();
  const defaultPayload = {
    id: "",
    name: "",
  };
  const [editMode, setEditMode] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [tableData, setTableData] = useState("");
  const [totalRows, setTotalRows] = useState(0);
  const [showDataTable, setShowDataTable] = useState(true);
  const [payload, setPayload] = useState(defaultPayload);
  //
  const columns = [
    {
      name: "id",
      title: "SNo",
      sorting: true,
    },
    {
      name: "name",
      title: "Name",
      sorting: true,
    },
    getRole() <= 2 && {
      name: "action",
      title: "Action",
      sorting: false,
    },
  ];
  const options = {
    theme: "#15a362",
    rows: [5, 10, 20, 50, 100],
    pagination: true,
    globalFilter: true,
  };
  const cusFilter = {
    page: 1,
    globalFilter: "",
    rows: 10,
    sortOrder: 1,
    sortField: "name",
    // areaid:3,
  };
  const [filter, setFilter] = useState(cusFilter);
  //
  const toggleDialog = () => {
    setShowDialog(!showDialog);
  };
  const editDialogHandler = (data) => {
    setPayload({ id: data.id, name: data.name });
    toggleDialog();
    setEditMode(true);
  };
  const addDialogHandler = () => {
    setPayload(defaultPayload);
    toggleDialog();
    setEditMode(false);
  };
  //
  const submitHandler = (payL) => {
    console.log("payl:", payL);
    if (payL.name === "") {
      toast("Department Name is Required");
    } else {
      if (editMode) {
        updaeAPIHandler(payL);
      } else {
        addAPIHandler(payL);
      }
      toggleDialog();
      handleShowDataTable(false);
    }
  };
  const addAPIHandler = (payL) => {
    addNewDepartment(payL)
      .then((response) => {
        if (response.data.status === "success") {
          toast.success(response.data.msg);
          resetForm();
        } else if (response.data.status === "warning") {
          swal("Already Registered !", response.data.msg, "warning");
        } else if (response.data.status === "error") {
          swal("Error.?", response.data.msg, "error");
        } else if (response.data.status === "logout") {
          logout();
          navigate("/login");
        } else {
          toast.error(
            "Error in Registering, Something went happned. " +
              response.data.status
          );
        }
        handleShowDataTable(true);
      })
      .catch((res) => {
        navigate("/dashboard");
        toast.error("Catch Error, Something went happned");
      });
  };
  const updaeAPIHandler = (payL) => {
    updateDepartment(payL)
      .then((response) => {
        if (response.data.status === "success") {
          toast.success(response.data.msg);
        } else if (response.data.status === "warning") {
          swal("Already Registered !", response.data.msg, "warning");
        } else if (response.data.status === "error") {
          swal("Error.?", response.data.msg, "error");
        } else if (response.data.status === "logout") {
          logout();
          navigate("/login");
        } else {
          toast.error(
            "Error in Updating, Something went happned. " + response.data.status
          );
        }
        handleShowDataTable(true);
      })
      .catch((res) => {
        navigate("/dashboard");
        toast.error("Catch Error, Something went happned");
      });
  };
  //
  const onLoad = (filterVal) => {
    getDepartmentsData(filterVal).then((res) => {
      let dta = res.data.data;
      // console.log(res.data);
      dta.map((el, key) => {
        let action = (
          <div style={{ width: "180px" }}>
            {/* Edit Handler Start*/}
            <OverlayTrigger
              placement="top"
              delay={{ show: 200, hide: 200 }}
              overlay={
                <Tooltip id="button-tooltip" {...props}>
                  Edit
                </Tooltip>
              }
            >
              <span
                className="pt-0 pb-0"
                style={{
                  marginRight: "10px",
                  color: "#15A362",
                  cursor: "pointer",
                }}
                onClick={() => editDialogHandler(el)}
              >
                <span className="fas fa-edit"></span>
              </span>
            </OverlayTrigger>
            {/* Edit Handler End*/}
            {/* Delete Handler Start */}
            <OverlayTrigger
              placement="top"
              delay={{ show: 200, hide: 200 }}
              overlay={
                <Tooltip id="button-tooltip" {...props}>
                  Delete
                </Tooltip>
              }
            >
              <span>
                <DialogDeleteData
                  id={el.id}
                  filter={filter}
                  lazyLoad={onLoad}
                  whatsDel={"department"}
                />
              </span>
            </OverlayTrigger>
            {/* Delete Handler End */}
          </div>
        );
        dta[key].action = action;
        return 0;
      });
      setTableData(dta);
      setTotalRows(res.data.total);
      setFilter(filterVal);
    });
  };
  const resetForm = () => {
    setPayload({ ...defaultPayload });
  };
  const handleShowDataTable = (callbackval) => {
    setShowDataTable(callbackval);
  };
  //
  return (
    <div className="app-content pt-3 p-md-3 p-lg-4">
      <div className="container-xl">
        <h1 className="app-page-title">Departments</h1>
        <div className="row">
          <DialogDeptDetails
            editmode={editMode}
            isVisible={showDialog}
            toggleVisible={toggleDialog}
            payload={payload}
            onSubmit={submitHandler}
          />
        </div>
        {getRole() <= 2 && (
          <div className="row">
            <div className="col-12 app-card-settings app-card pt-3 pb-3">
              <div
                className="col-md-2"
                style={{ float: "left", paddingLeft: 3, paddingRight: 3 }}
              >
                <button
                  className="btn app-btn-primary"
                  style={{ width: "100%" }}
                  onClick={addDialogHandler}
                >
                  <span className="fa fa-plus-circle"></span> Add New Department
                </button>
              </div>
            </div>
          </div>
        )}
        <div className="row" style={{ height: "10px" }}></div>
        {showDataTable ? (
          <div className="row">
            <div className="col-12 app-card-settings app-card pt-1 pb-3">
              <Datatable
                options={options}
                columns={columns}
                filter={filter}
                data={tableData}
                totalRows={totalRows}
                lazyLoad={onLoad}
              ></Datatable>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};
export default ViewDepartments;
