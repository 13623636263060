import React, { useState, useEffect } from "react";
import { getRole } from "../../services/AuthService";
import {
  getDesignationList,
  addNewDesignation,
  updateDesignation,
} from "../../services/HTTPService";
import { logout } from "../../services/AuthService";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import swal from "sweetalert";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import DialogDesigDetails from "../../components/Shared/DialogsBoxes/DialogDesigDetails";
import DialogDeleteData from "../../components/Shared/DialogsBoxes/DialogDeleteData";
import MUIDataTable from "mui-datatables";
import { Button } from "@mui/material";
//
const ViewDesignation = (props) => {
  const columns = [
    {
      name: "sn",
      label: "SN",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "name",
      label: "Designation",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "action",
      label: "Action",
      options: {
        display: getRole() <= 2 ? true : "excluded",
        filter: false,
        sort: false,
      },
    },
  ];
  const options = {
    jumpToPage: true,
    fixedHeader: false, // table header remain fix when dialog box open
    selectableRows: "none", //"multiple", "single", "none".
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 10, 20, 50, 100],
    download: true, //enum('true', 'false', 'disabled')
    downloadOptions: {
      filename: "designation.csv",
      separator: ",",
      filterOptions: {
        useDisplayedColumnsOnly: true,
      },
    },
    filter: true, //enum('true', 'false', 'disabled')
    responsive: "standard",
    filterType: "multiselect", //enum('checkbox', 'dropdown', 'multiselect', 'textField', 'custom')
    print: "disabled", //enum('true', 'false', 'disabled')
    viewColumns: "true", //show column select box: enum('true', 'false', 'disabled')
    searchPlaceholder: "Search Here...",

    // Calling the applyNewFilters parameter applies the selected filters to the table
    // confirmFilters: true,
    // customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
    //   return (
    //     <div style={{ marginTop: "20px" }}>
    //       <Button variant="contained" onClick={applyNewFilters}>
    //         Apply Filters
    //       </Button>
    //     </div>
    //   );
    // },
    // searchAlwaysOpen: true,
    // sortDescFirst: true,
  };
  const defaultPayload = {
    id: "",
    name: "",
  };
  const navigate = useNavigate();
  const [muiData, setMUIData] = useState([]);
  //
  const [editMode, setEditMode] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [showDataTable, setShowDataTable] = useState(true);
  const [payload, setPayload] = useState(defaultPayload);
  //
  const toggleDialog = () => {
    setShowDialog(!showDialog);
  };
  const editDialogHandler = (data) => {
    setPayload({ id: data.id, name: data.name });
    toggleDialog();
    setEditMode(true);
  };
  const addDialogHandler = () => {
    setPayload(defaultPayload);
    toggleDialog();
    setEditMode(false);
  };
  const resetForm = () => {
    setPayload({ ...defaultPayload });
  };
  const submitHandler = (payL) => {
    console.log("payl:", payL);
    if (payL.name === "") {
      toast("Designation Name is Required");
    } else {
      if (editMode) {
        updaeAPIHandler(payL);
      } else {
        addAPIHandler(payL);
      }
      toggleDialog();
      handleShowDataTable(false);
    }
  };
  const handleShowDataTable = (callbackval) => {
    setShowDataTable(callbackval);
  };
  const addAPIHandler = (payL) => {
    addNewDesignation(payL)
      .then((response) => {
        if (response.data.status === "success") {
          toast.success(response.data.msg);
          resetForm();
        } else if (response.data.status === "warning") {
          swal("Already Registered !", response.data.msg, "warning");
        } else if (response.data.status === "error") {
          swal("Error.?", response.data.msg, "error");
        } else if (response.data.status === "logout") {
          logout();
          navigate("/login");
        } else {
          toast.error(
            "Error in Registering, Something went happned. " +
              response.data.status
          );
        }
        handleShowDataTable(true);
      })
      .catch((res) => {
        navigate("/dashboard");
        toast.error("Catch Error, Something went happned");
      });
  };
  const updaeAPIHandler = (payL) => {
    updateDesignation(payL)
      .then((response) => {
        if (response.data.status === "success") {
          toast.success(response.data.msg);
        } else if (response.data.status === "warning") {
          swal("Already Registered !", response.data.msg, "warning");
        } else if (response.data.status === "error") {
          swal("Error.?", response.data.msg, "error");
        } else if (response.data.status === "logout") {
          logout();
          navigate("/login");
        } else {
          toast.error(
            "Error in Updating, Something went happned. " + response.data.status
          );
        }
        handleShowDataTable(true);
      })
      .catch((res) => {
        navigate("/dashboard");
        toast.error("Catch Error, Something went happned");
      });
  };
  const getDesignation = () => {
    getDesignationList().then((response) => {
      if (response.status === 200) {
        console.log(response.data);
        let dta = response.data;
        dta.map((el, key) => {
          let action = getRole() <= 2 && (
            <div style={{ width: "180px" }}>
              {/* Edit Handler Start*/}
              <OverlayTrigger
                placement="top"
                delay={{ show: 200, hide: 200 }}
                overlay={
                  <Tooltip id="button-tooltip" {...props}>
                    Edit
                  </Tooltip>
                }
              >
                <span
                  className="pt-0 pb-0"
                  style={{
                    marginRight: "10px",
                    color: "#15A362",
                    cursor: "pointer",
                  }}
                  onClick={() => editDialogHandler(el)}
                >
                  <span className="fas fa-edit"></span>
                </span>
              </OverlayTrigger>
              {/* Edit Handler End*/}
              {/* Delete Handler Start */}
              <OverlayTrigger
                placement="top"
                delay={{ show: 200, hide: 200 }}
                overlay={
                  <Tooltip id="button-tooltip" {...props}>
                    Delete
                  </Tooltip>
                }
              >
                <span>
                  <DialogDeleteData
                    id={el.id}
                    filter={options}
                    lazyLoad={getDesignation}
                    whatsDel={"designation"}
                  />
                </span>
              </OverlayTrigger>
              {/* Delete Handler End */}
            </div>
          );
          dta[key].action = action;
          return 0;
        });
        setMUIData(dta);
      } else {
        navigate(`/dashboard`);
        console.log(response.data);
        toast.error(response.data.msg);
      }
    });
  };
  useEffect(() => {
    getDesignation();
  }, [showDataTable]);
  //

  return (
    <div className="app-content pt-3 p-md-3 p-lg-4">
      <div className="container-xl">
        <h1 className="app-page-title">Designation</h1>
        <div className="row">
          <DialogDesigDetails
            editmode={editMode}
            isVisible={showDialog}
            toggleVisible={toggleDialog}
            payload={payload}
            onSubmit={submitHandler}
          />
        </div>
        {getRole() <= 2 && (
          <div className="row">
            <div className="col-12 app-card-settings app-card pt-3 pb-3">
              <div
                className="col-md-2"
                style={{ float: "left", paddingLeft: 3, paddingRight: 3 }}
              >
                <button
                  className="btn app-btn-primary"
                  style={{ width: "100%" }}
                  onClick={addDialogHandler}
                >
                  <span className="fa fa-plus-circle"></span> Add New
                  Designation
                </button>
              </div>
            </div>
          </div>
        )}
        <div className="row" style={{ height: "10px" }}></div>
        <div
          className="row"
          style={{ height: "10px", backgroundColor: "#15a362" }}
        ></div>
        <div className="row" style={{ height: "10px" }}></div>

        {showDataTable ? (
          <div className="row">
            <div className="col-12 app-card-settings app-card pt-1 pb-3">
              <MUIDataTable
                title={"Designation List"}
                data={muiData}
                columns={columns}
                options={options}
              />
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};
export default ViewDesignation;
