import React, { useState, useEffect } from "react";
import AppRoutes from "../routes/Routes";
import { NavLink } from "react-router-dom";
import { getRolesAccess } from "../services/HTTPService";
import "./style/sidebar.css";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
// import { AppRoute } from "../routes/Routes";
//
// Database submodules table :icon(varchar),path(varchar) added
// Database module table :icon(varchar) added
//
export default function Sidebar(props) {
  // AppRoute();  
  const navigate = useNavigate();
  const [sidebarMenu, setSidebarMenu] = useState([]);
  const getRoles = () => {
    getRolesAccess().then((response) => {
      if (response.status === 200) {
        setSidebarMenu(response.data);
      } else {
        navigate(`/dashboard`);
        console.log(response.data);
        toast.error(response.data.msg);
      }
    });
  };
  useEffect(() => {
    getRoles();
  }, []);
  //
  const menuItems = [...new Set(sidebarMenu.map((val) => val.modulename))];
  // console.log(menuItems);
  //
  return (
    <nav id="app-nav-main" className="app-nav app-nav-main flex-grow-1">
      {/* <ul className="app-menu list-unstyled accordion" id="menu-accordion">
        {AppRoutes.map((el, key) => {
          let iconClass = ["sidebar-icon"];
          iconClass.push(el.icon);
          iconClass = iconClass.join(" ");
          let path = "" + el.path;
          let navId = "submenu-" + key;
          let navIdTarget = "#submenu-" + key;
          //
          if (!el.hasChild) {
            return el.showOnSidebar ? (
              <li key={key} className="nav-item">
                <NavLink className="nav-link" to={path} onClick={props.toggle}>
                  <span className="nav-icon">
                    <span className={iconClass}></span>
                  </span>
                  <span className="nav-link-text">{el.name}</span>
                </NavLink>
              </li>
            ) : null;
          } else {
            return (
              <li key={key} className="nav-item has-submenu">
                <a
                  className="nav-link submenu-toggle"
                  href="#0"
                  data-bs-toggle="collapse"
                  data-bs-target={navIdTarget}
                  aria-expanded="false"
                  aria-controls={navId}
                >
                  <span className="nav-icon">
                    <span className={iconClass}></span>
                  </span>
                  <span className="nav-link-text">{el.name}</span>
                  <span className="submenu-arrow">
                    <span className="fa fa-chevron-down sidebar-icon"></span>
                  </span>
                </a>
                <div
                  id={navId}
                  className={["collapse", "submenu", navId].join(" ")}
                  data-bs-parent="#menu-accordion"
                >
                  <ul className="submenu-list list-unstyled">
                    {el.children.map((child, ky) => {
                      let childIcon = ["sidebar-icon"];
                      childIcon.push(child.icon);
                      childIcon = childIcon.join(" ");
                      let childPath = "" + child.path;
                      return child.showOnSidebar ? (
                        <li key={ky} className="submenu-item">
                          <NavLink
                            className="submenu-link"
                            to={childPath}
                            onClick={props.toggle}
                          >
                            {child.name}
                          </NavLink>
                        </li>
                      ) : null;
                    })}
                  </ul>
                </div>
              </li>
            );
          }
        })}
      </ul> */}
      {/* ///////// */}
      {/* ///////// */}
      {/* ===== From DB ======== */}
      {/* ///////// */}
      {/* ///////// */}
      <ul className="app-menu list-unstyled accordion" id="menu-accordion">
        <li className="nav-item">
          <NavLink className="nav-link" to="/dashboard" onClick={props.toggle}>
            <span className="nav-icon">
              <span className="fa fa-tachometer-alt"></span>
            </span>
            <span className="nav-link-text">Dashboard</span>
          </NavLink>
        </li>
        {menuItems.map((menu, index) => {
          let mIconClass = ["sidebar-icon"];
          mIconClass.push("fa fa-user-tie");
          mIconClass = mIconClass.join(" ");
          let mPath = "";
          let navId = "submenu-" + index + 10;
          let navIdTarget = "#submenu-" + index + 10;
          return (
            <li key={index} className="nav-item has-submenu">
              <a
                className="nav-link submenu-toggle"
                href="#0"
                data-bs-toggle="collapse"
                data-bs-target={navIdTarget}
                aria-expanded="false"
                aria-controls={navId}
              >
                <span className="nav-icon">
                  <span className={mIconClass}></span>
                </span>
                <span className="nav-link-text">{menu}</span>
                <span className="submenu-arrow">
                  <span className="fa fa-chevron-down sidebar-icon"></span>
                </span>
              </a>
              <div
                id={navId}
                className={["collapse", "submenu", navId].join(" ")}
                data-bs-parent="#menu-accordion"
              >
                <ul className="submenu-list list-unstyled">
                  {sidebarMenu.map((child, ky) => {
                    let childIcon = ["sidebar-icon"];
                    childIcon.push(child.smicon);
                    childIcon = childIcon.join(" ");
                    let childPath = "" + child.path;
                    return child.modulename === menu && child.showonsidebar ? (
                      <li key={ky} className="submenu-item">
                        <NavLink
                          className="submenu-link"
                          to={childPath}
                          onClick={props.toggle}
                        >
                          {child.submodulename}
                        </NavLink>
                      </li>
                    ) : null;
                  })}
                </ul>
              </div>
            </li>
          );
        })}
      </ul>
    </nav>
  );
}
