import React, { useEffect } from "react";
import "./datatable.css";

export default function Datatable(props) {
  const defaultColumns = [
    {
      name: "col1",
      title: "Column 1",
      sorting: true,
      sort: 1,
      width: 50,
    },
    {
      name: "col2",
      title: "Column 2",
      sorting: true,
      sort: 1,
      width: 50,
    },
  ];
  const defaultOptions = {
    theme: "#009f56",
    rows: [10, 20, 50, 100],
    pagination: true,
    globalFilter: true,
  };
  const defaultData = [];
  const columns = props.columns ? props.columns : defaultColumns;
  const defaultFilter = {
    page: 1,
    rows: 10,
    sortOrder: -1,
    sortField: columns[0].name,
    globalFilter: "",
  };
  const filter = props.filter ? props.filter : defaultFilter;
  const options = props.options ? props.options : defaultOptions;
  const data = props.data ? props.data : defaultData;
  const lazyLoad = props.lazyLoad; //Function to be called from parent component to get lazy loaded data
  const totalRows = props.totalRows;
  useEffect(() => {
    lazyLoad(filter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pageChanged = (event) => {
    let val = event.target.value;
    if (val > 0 && val <= Math.ceil(totalRows / filter.rows)) {
      let changedFilter = filter;
      changedFilter.page = val;
      lazyLoad(changedFilter);
    }
  };
  const next = () => {
    if (totalRows - filter.rows * filter.page) {
      let changedFilter = filter;
      changedFilter.page++;
      lazyLoad(changedFilter);
    }
  };
  const prev = () => {
    if (filter.page >= 2) {
      let changedFilter = filter;
      changedFilter.page--;
      lazyLoad(changedFilter);
    }
  };
  const globalFilterChanged = (event) => {
    let val = event.target.value;
    let changedFilter = filter;
    changedFilter.globalFilter = val;
    lazyLoad(changedFilter);
  };
  const handleClearSearch = () => {
    let changedFilter = filter;
    changedFilter.globalFilter = "";
    lazyLoad(changedFilter);
  };
  const rowsChanged = (event) => {
    let changedFilter = filter;
    changedFilter.rows = event.target.value;
    changedFilter.page = 1;
    lazyLoad(changedFilter);
  };
  const sortOrderChanged = (col) => {
    // console.log('yes')
    let changedFilter = filter;
    changedFilter.sortField = col;
    changedFilter.sortOrder = changedFilter.sortOrder * -1;
    lazyLoad(changedFilter);
  };

  const styles = {
    headerStyle: {
      color: "#fff",
      background: options.theme,
      whiteSpace: "nowrap",
      overflow: "hidden",
    },
    paginatorInput: {
      width: "100px",
      textAlign: "center",
    },
  };
  return (
    <>
      <div className="row p-3">
        <div className="col-12 col-md-2">
          <select
            className="form-select"
            value={filter.rows}
            onChange={rowsChanged}
          >
            {options.rows.map((el, k) => {
              return (
                <option key={k} value={el}>
                  {el} Rows
                </option>
              );
            })}
          </select>
        </div>
        <div className="col-12 col-md-5"></div>
        <div className="col-12 col-md-5 search-wrapper">
          <input
            type="text"
            className="search-box"
            placeholder="Search"
            value={filter.globalFilter}
            onChange={globalFilterChanged}
            autoFocus
          />
          <button
            className="close-icon"
            type="reset"
            onClick={handleClearSearch}
          ></button>
        </div>
      </div>
      <hr className="m-0" />
      <div className="row">
        <div className="col-12 table-container">
          <table className="data-table" style={{ minWidth: "100%" }}>
            <thead>
              <tr style={styles.headerStyle}>
                {columns.map((cols) => {
                  return (
                    <th
                      key={Math.random()}
                      style={{
                        width: cols.width ? cols.width : "auto",
                        cursor: "pointer",
                      }}
                      onClick={() => sortOrderChanged(cols.name)}
                    >
                      {cols.title}
                      <span
                        className={[
                          "mt-1",
                          cols.sorting === true
                            ? filter.sortField === cols.name &&
                              filter.sortOrder === 1
                              ? "fas fa-sort-amount-down-alt"
                              : filter.sortField === cols.name &&
                                filter.sortOrder === -1
                              ? "fas fa-sort-amount-down"
                              : "fas fa-sort"
                            : "",
                        ].join(" ")}
                        style={{ marginLeft: "10px", whiteSpace: "pre" }}
                      ></span>
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {data.length > 0 ? (
                data.map((row, key) => {
                  return (
                    <tr
                      className={key % 2 === 0 ? "" : "odd-row"}
                      key={Math.random()}
                    >
                      {columns.map((col) => {
                        return <td key={Math.random()}>{row[col.name]}</td>;
                      })}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td>No Records Found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="col-12">
          <hr className="mt-0" />
        </div>

        <div className="col-12 col-md-6" style={{ paddingLeft: "20px" }}>
          <h6 className="mt-2">
            Showing {(filter.page - 1) * filter.rows + data.length} of{" "}
            {props.totalRows} records
          </h6>
        </div>
        <div className="col-12 col-md-6" style={{ paddingRight: "20px" }}>
          <nav aria-label="Page navigation example">
            <ul className="pagination justify-content-end">
              <li
                className={[
                  "page-item",
                  filter.page >= 2 ? "" : "disabled",
                ].join(" ")}
              >
                <button className="page-link" onClick={prev}>
                  Prev
                </button>
              </li>
              <li className="page-item">
                <input
                  type="number"
                  value={filter.page}
                  onChange={pageChanged}
                  className="form-control"
                  style={styles.paginatorInput}
                />
              </li>
              <li
                className={[
                  "page-item",
                  totalRows - filter.rows * filter.page > 0 ? "" : "disabled",
                ].join(" ")}
              >
                <button className="page-link" onClick={next}>
                  Next
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
}
