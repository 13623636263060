import React, { useState, useEffect } from "react";
import { getRole, getAreaId } from "../../../services/AuthService";
import { validations } from "../../../services/ValidationService";
import { getAreaList } from "../../../services/HTTPService";
import { toast } from "react-toastify";
export default function DialogApproveDevice(props) {
  const defaultPayload = {
    id: 0,
    oldarea: 0,
    area: "",
    areaid: 0,
    name: "",
    contact: "",
    statusid: 1,
    asbutton: 0,
    approvedby: "",
    deviceid: "",
    model: "",
    attempt: 0,
    updatedat: "",
  };
  const [payload, setPayload] = useState(defaultPayload);
  const [editMode, setEditMode] = useState(false);
  const [area, setArea] = useState([]);
  const toggleVisible = props.toggleVisible;
  const [loading, setLoading] = useState(true);
  //
  useEffect(() => {
    setPayload(props.payload);
  }, [props.payload]);
  //
  useEffect(() => {
    setEditMode(props.editmode);
    // console.log("Edit Mode:", props.editmode);
  }, [props.editmode]);
  //
  const cancelHandler = () => {
    toggleVisible();
  };
  const trigger = () => {
    props.onSubmit(payload);
  };
  const updateFieldsData = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "contact":
        if (value === "" || validations.numberOnly(value)) {
          setPayload({ ...payload, [name]: value });
        }
        break;
      default:
        setPayload({ ...payload, [name]: value });
        break;
    }
  };
  const getArea = () => {
    if (loading === true) {
      setLoading(false);
      getAreaList().then((response) => {
        if (response.status === 200) {
          setArea(response.data);
        } else {
          // console.log(response.data);
          toast.error(response.data.msg);
        }
      });
    }
  };
  useEffect(() => {
    getArea();
  });
  //
  const view = () => {
    return (
      <>
        <div className="dialog-container">
          <div className="">
            <h4 style={{ textAlign: "center" }}>
              {editMode ? "Update User Details" : "For User Approval"}
            </h4>
            {editMode ? (
              <div className="mt-2 mb-2">
                <label className="form-label" htmlFor="">
                  Already Assigned Area <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  name="oldarea"
                  value={payload.oldarea}
                  className="form-control"
                  placeholder="Already Area"
                  onChange={updateFieldsData}
                  disabled
                ></input>
              </div>
            ) : null}
            <div className="mt-2 mb-2">
              <label className="form-label" htmlFor="">
                Select New Area <span style={{ color: "red" }}>*</span>
              </label>
              <select
                className="form-select"
                name="areaid"
                value={payload.areaid}
                onChange={updateFieldsData}
              >
                <option value="">Select Area</option>
                {area.map((val, key) => {
                  return (
                    <>
                      {getRole() <= 2 ? (
                        <option key={key} value={val.id}>
                          {val.name}
                        </option>
                      ) : getAreaId() === val.id ? (
                        <option key={key} value={val.id}>
                          {val.name}
                        </option>
                      ) : null}
                    </>
                  );
                })}
              </select>
            </div>
            <div className="mt-2 mb-2">
              <label className="form-label" htmlFor="">
                Name <span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="text"
                name="name"
                value={payload.name}
                className="form-control"
                placeholder="Name"
                onChange={updateFieldsData}
              ></input>
            </div>
            <div className="mt-2 mb-2">
              <label className="form-label" htmlFor="">
                Contact
              </label>
              <input
                type="text"
                name="contact"
                value={payload.contact}
                className="form-control"
                placeholder="contact"
                maxLength={10}
                onChange={updateFieldsData}
              ></input>
            </div>
            <div className="mt-2 mb-2">
              <label className="form-label" htmlFor="">
                Select Status <span style={{ color: "red" }}>*</span>
              </label>
              <select
                className="form-select"
                name="statusid"
                value={payload.statusid}
                onChange={updateFieldsData}
              >
                {/* <option value="">Select Status</option> */}
                <option value={1}>Active</option>
                <option value={0}>Not</option>
              </select>
            </div>
            {getRole() <= 2 && (
              <>
                <div className="mt-2 mb-2">
                  <label className="form-label" htmlFor="">
                    ASButton Status
                  </label>
                  <select
                    className="form-select"
                    name="asbutton"
                    value={payload.asbutton}
                    onChange={updateFieldsData}
                  >
                    {/* <option value="">Select Status</option> */}
                    <option value={0}>Not</option>
                    <option value={1}>Active</option>
                  </select>
                </div>
              </>
            )}
          </div>
          <div className="button-container">
            <button
              className="btn app-btn-secondary"
              onClick={() => cancelHandler()}
            >
              Cancel
            </button>
            <button className="btn app-btn-primary" onClick={trigger}>
              {editMode ? "Update" : "Approve"}
            </button>
          </div>
        </div>
        <div className="confirm-bg"></div>
      </>
    );
  };

  return <div>{props.isVisible ? view() : ""}</div>;
}
