import React, { useState, useEffect } from "react";
import { getRole,getAreaId } from "../../services/AuthService";
import { toast } from "react-toastify";
import swal from "sweetalert";
import {
  getAreaList,
  getMCDeptList,
  getSewadars4mDesig,
  assignDepartment,
} from "../../services/HTTPService";
import { logout } from "../../services/AuthService";
import { useNavigate } from "react-router-dom";
import { validations } from "../../services/ValidationService";
import ViewDirWithDept from "./ViewDirWithDept";
//
export default function AssignDeptL2Other() {
  const navigate = useNavigate();
  const myprops = {
    showAddBtn: false,
  };
  const defaultfields = {
    areaid: "",
    area: "",
    department: "",
    departmentid: "",
    sewadar: "",
    sewadarid: "",
    priority: "0",
  };
  const [loading, setLoading] = useState(true);
  const [fieldsData, setFieldsData] = useState(defaultfields);
  const [area, setArea] = useState([]);
  const [department, setDepartment] = useState([]);
  const [sewadars, setSewadars] = useState([]);
  const [showDataTable, setShowDataTable] = useState(true);
  //
  const getArea = () => {
    if (loading === true) {
      setLoading(false);
      getAreaList().then((response) => {
        if (response.status === 200) {
          setArea(response.data);
        } else {
          navigate(`/dashboard`);
          console.log(response.data);
          toast.error(response.data.msg);
        }
      });
    }
  };
  const getDepartment = () => {
    getMCDeptList().then((response) => {
      if (response.status === 200) {
        setDepartment(response.data);
      } else {
        navigate(`/dashboard`);
        console.log(response.data);
        toast.error(response.data.msg);
      }
    });
  };
  const getSewadars = () => {
    getSewadars4mDesig({ desig: "2-3-6" }).then((response) => { // only AS/Sec/Presidents
      if (response.status === 200) {
        if (response.data.status === "success") {
          // console.log(response.data);
          setSewadars(response.data.data);
        } else {
          console.log(response.data.msg);
          toast.error(response.data.msg);
        }
      } else {
        console.log("error");
        navigate(`/dashboard`);
      }
    });
  };
  useEffect(() => {
    getArea();
    getDepartment();
    getSewadars();
    // console.log("Sewadars", sewadars);
  }, []);
  //
  const updateFieldsData = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "priority":
        if (value === "" || validations.numberOnly(value)) {
          setFieldsData({ ...fieldsData, [name]: value });
        }
        break;
      default:
        setFieldsData({ ...fieldsData, [name]: value });
        break;
    }
  };
  const checkValidation = () => {
    if (fieldsData.areaid === "") {
      toast("Please Select Area");
    } else if (fieldsData.sewadarsid === "") {
      toast("Please Select Sewadar");
    } else if (fieldsData.departmentid === "") {
      toast("Please select Department");
    } else {
      assignDepartment(fieldsData)
        .then((response) => {
          if (response.data.status === "success") {
            console.log(response.data);
            toast.success(response.data.msg);
          } else if (response.data.status === "warning") {
            console.log(response.data);
            swal("Already Registered !", response.data.msg, "warning");
          } else if (response.data.status === "error") {
            console.log(response.data);
            swal("Error.?", response.data.msg, "error");
          } else if (response.data.status === "logout") {
            logout();
            navigate("/login");
          } else {
            toast.error(
              "Error in Registering, Something went happned. " +
                response.data.status
            );
          }
          setShowDataTable(true);
        })
        .catch((res) => {
          navigate("/dashboard");
          toast.error("Error in Registering, Something went happned");
        });
      setShowDataTable(false);
    }
  };
  //
  return (
    <>
      <div className="app-content pt-3 p-md-3 p-lg-4">
        <div className="container-xl">
          <h1 className="app-page-title">
            ASSIGN DEPT. (LOCAL TO OTHER AREA)
          </h1>
          <span>
            Department Assign Local Area Sewadars to Other Area: (*) field is
            mandatory
          </span>
          <div className="row">
            <div className="col-12 app-card-settings app-card pt-3 pb-3">
              <div className="row mb-4">
                <div className="col-md-4 col-12">
                  <label className="form-label" htmlFor="">
                    AS/SEC/PRESIDENT/HODS{" "}
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <select
                    className="form-select"
                    name="sewadarid"
                    value={fieldsData.sewadarid}
                    onChange={updateFieldsData}
                  >
                    <option value="">Select Sewadar</option>
                    {sewadars.map((val, key) => {
                      return (
                        <option key={key} value={val.id}>
                          {val.name} [ {val.designation + ": " + val.area}]
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="col-md-3 col-12">
                  <label className="form-label" htmlFor="">
                    SEWA AREA <span style={{ color: "red" }}>*</span>
                  </label>
                  <select
                    className="form-select"
                    name="areaid"
                    value={fieldsData.areaid}
                    onChange={updateFieldsData}
                  >
                    <option value="">Select Place</option>
                    {area.map((val, key) => {
                       return (
                        <>
                          {getRole() <= 2 ? (
                            <option key={key} value={val.id}>
                              {val.name}
                            </option>
                          ) : getAreaId() === val.id ? (
                            <option key={key} value={val.id}>
                              {val.name}
                            </option>
                          ) : null}
                        </>
                      );
                    })}
                  </select>
                </div>
                <div className="col-md-3 col-12">
                  <label className="form-label" htmlFor="">
                    Department<span style={{ color: "red" }}>*</span>
                  </label>
                  <select
                    className="form-select"
                    name="departmentid"
                    value={fieldsData.departmentid}
                    onChange={updateFieldsData}
                  >
                    <option value="">Select Department</option>
                    {department.map((val, key) => {
                      return (
                        <option key={key} value={val.id}>
                          {val.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="col-md-2 col-12">
                  <label className="form-label" htmlFor="">
                    Priority
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    name="priority"
                    maxLength={3}
                    value={fieldsData.priority}
                    onChange={updateFieldsData}
                    placeholder="Priority"
                  />
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-md-4 col-12"></div>
                <div className="col-md-4" style={{ float: "right" }}>
                  <button
                    className="btn app-btn-primary"
                    style={{ width: "100%" }}
                    onClick={checkValidation}
                  >
                    {"Assign Department"}
                  </button>
                </div>
                <div className="col-md-4 col-12"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showDataTable ? <ViewDirWithDept myprops={myprops} /> : null}
    </>
  );
}
