import axios from "axios";

export const authenticateService = (params) => {
  return httpPOST("/Authenticate", params);
};
//

//Getting Table Data
export const getDirectoryData = async (params) => {
  return httpPOST("/GetDirectoryData", params);
};
export const getDirectoryDeptData = async (params) => {
  return httpPOST("/GetDirectoryDeptData", params);
};
export const getAreasData = (params) => {
  return httpPOST("/GetAreasData", params);
};
export const getCentresData = (params) => {
  return httpPOST("/GetCentresData", params);
};
export const getPlacesData = (params) => {
  return httpPOST("/GetPlacesData", params);
};
export const getDepartmentsData = (params) => {
  return httpPOST("/GetDepartmentsData", params);
};
export const getDesignationData = (params) => {
  return httpPOST("/GetDesignationData", params);
};
export const getRolesData = (params) => {
  return httpPOST("/GetRolesData", params);
};
export const getUsersData = (params) => {
  return httpPOST("/GetUsersData", params);
};
export const getDevicePendingData = (params) => {
  return httpPOST("/GetDevicePendingData", params);
};
export const getDeviceApprovedData = (params) => {
  return httpPOST("/GetDeviceApprovedData", params);
};
//
//Getting Multiple Listing Data
export const getDashboardData = (params) => {
  return httpPOST("/GetDashboard", params);
};

export const getContactDetails = async (params) => {
  return httpPOST("/GetContactDetails", params);
};
export const getDeptContactDetails = async (params) => {
  return httpPOST("/GetDeptContactDetails", params);
};
export const getUserDetails = async (params) => {
  return httpPOST("/GetUserDetails", params);
};
export const printDirData = async (params) => {
  return httpPOST("/PrintDirData", params);
};
export const printApprovedDevices = async (params) => {
  return httpPOST("/PrintApprovedDevices", params);
};
export const printDirDeptData = async (params) => {
  return httpPOST("/PrintDirDeptData", params);
};
//
// Getting Single Listing Data
export const getAreaList = () => {
  return httpGET("/GetAreaList");
};
export const getCentresList = () => {
  return httpGET("/GetCentresList");
};
export const getDesignationList = () => {
  return httpGET("/GetDesignationList");
};
// export const getDesignationList = async (params) => {
//   return httpPOST("/GetDesignationList", params);
// };
export const getRolesList = () => {
  return httpGET("/GetRolesList");
};
export const getRolesAccess = () => {
  return httpGET("/GetRolesAccess");
};
export const getMCDeptList = () => {
  return httpGET("/GetMCDeptList");
};
export const getPlaceTypeList = () => {
  return httpGET("/GetPlaceTypeList");
};
//

export const getPlaceById = async (params) => {
  return httpPOST("/GetPlaceById", params);
};
export const getPlaces4mAreaId = (params) => {
  return httpPOST("/GetPlaces4mAreaId", params);
};
export const getPlaces4mCentreId = (params) => {
  return httpPOST("/GetPlaces4mCentreId", params);
};
export const getCentre4mAreaId = (params) => {
  return httpPOST("/GetCentre4mAreaId", params);
};
export const getCentre4mPlaceId = (params) => {
  return httpPOST("/GetCentre4mPlaceId", params);
};
export const getSewadards4mPlaceId = (params) => {
  return httpPOST("/GetSewadards4mPlaceId", params);
};
export const getSewadars4mDesig = (params) => {
  return httpPOST("/GetSewadars4mDesig", params);
};

//
// Register / Post Data
export const addNewContact = async (params) => {
  return httpPOST("/AddNewContact", params);
};
export const addNewArea = async (params) => {
  return httpPOST("/AddNewArea", params);
};
export const addNewCentre = async (params) => {
  return httpPOST("/AddNewCentre", params);
};
export const addNewPlace = async (params) => {
  return httpPOST("/AddNewPlace", params);
};
export const assignDepartment = async (params) => {
  return httpPOST("/AssignDepartment", params);
};
export const addNewDepartment = async (params) => {
  return httpPOST("/AddNewDepartment", params);
};
export const addNewDesignation = async (params) => {
  return httpPOST("/AddNewDesignation", params);
};
export const addNewRole = async (params) => {
  return httpPOST("/AddNewRole", params);
};
export const approveDevice = async (params) => {
  return httpPOST("/ApproveDevice", params);
};
export const addNewUser = async (params) => {
  return httpPOST("/AddNewUser", params);
};
//
// Upadate / Post Data
export const updatePasswordService = (params) => {
  return httpPOST("/UpdatePassword", params);
};
export const updateContact = async (params) => {
  return httpPOST("/UpdateContact", params);
};
export const updateAssignedDept = async (params) => {
  return httpPOST("/UpdateAssignDepartment", params);
};
export const updateAreas = async (params) => {
  return httpPOST("/UpdateAreas", params);
};
export const updateCentre = async (params) => {
  return httpPOST("/UpdateCentre", params);
};
export const updatePlace = async (params) => {
  return httpPOST("/UpdatePlace", params);
};
export const updateDepartment = async (params) => {
  return httpPOST("/UpdateDepartment", params);
};
export const updateDesignation = async (params) => {
  return httpPOST("/UpdateDesignation", params);
};
export const updateRoles = async (params) => {
  return httpPOST("/UpdateRoles", params);
};
export const updateApprovedDevice = async (params) => {
  return httpPOST("/UpdateApprovedDevice", params);
};
export const updateUser = async (params) => {
  return httpPOST("/UpdateUser", params);
};
export const updatePwd = async (params) => {
  return httpPOST("/UpdatePassword", params);
};
//
// Delete Services
export const delDataByID = (params) => {
  return httpPOST("/DeleteDataByID", params);
};
export const deleteDevice = (params) => {
  return httpPOST("/DeleteDevice", params);
};
export const delContact = (params) => {
  return httpPOST("/DeleteContact", params);
};

//Third Party Services
// export const getSeedGrowerDetailsService = (params) => {
//   return httpGET("https://asscu.nic.in/api/getBlocksOfDistrict?distcode=50");
// };
// export const getBlocksService = (params) => {
//   return httpGET(
//     "https://asscu.nic.in/api/getBlocksOfDistrict?distcode=" + params
//   );
// };

//Action Triggers
const httpPOST = (url, params) => {
  return axios.post(url, { params: params });
};
const httpGET = (url) => {
  return axios.get(url);
};
