import React, { useState } from "react";
import { getRole } from "../../services/AuthService";
import {
  getDeviceApprovedData,
  updateApprovedDevice,
} from "../../services/HTTPService";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { logout } from "../../services/AuthService";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import swal from "sweetalert";
import Datatable from "../../components/Shared/Datatable/Datatable";
import DialogApproveDevice from "../../components/Shared/DialogsBoxes/DialogApproveDevice";
import DialogDeleteData from "../../components/Shared/DialogsBoxes/DialogDeleteData";
//
const ViewDeviceApproved = (props) => {
  const navigate = useNavigate();
  const defaultPayload = {
    id: 0,
    oldarea: "",
    area: "",
    areaid: 0,
    name: "",
    contact: "",
    statusid: 0,
    approvedby: "",
    deviceid: "",
    model: "",
    attempt: "",
    updatedat: "",
  };
  const [editMode, setEditMode] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [tableData, setTableData] = useState("");
  const [totalRows, setTotalRows] = useState(0);
  const [showDataTable, setShowDataTable] = useState(true);
  const [payload, setPayload] = useState(defaultPayload);
  //
  const columns = [
    {
      name: "id",
      title: "SNo",
      sorting: true,
    },
    {
      name: "deviceid",
      title: "DeviceID",
      sorting: true,
    },
    {
      name: "area",
      title: "Area",
      sorting: true,
    },
    {
      name: "name",
      title: "Name",
      sorting: true,
    },
    {
      name: "contact",
      title: "Mobile",
      sorting: true,
    },
    {
      name: "status",
      title: "Status",
      sorting: true,
    },
    getRole() == 1 && {
      name: "asbuttonstatus",
      title: "AS Enable",
      sorting: true,
    },
    {
      name: "updatedat",
      title: "Last Update",
      sorting: true,
    },
    (getRole() <= 3 || getRole() == 6) && {
      name: "action",
      title: "Action",
      sorting: false,
    },
  ];
  const options = {
    theme: "#15a362",
    rows: [10, 20, 50, 100],
    pagination: true,
    globalFilter: true,
  };
  const cusFilter = {
    page: 1,
    globalFilter: "",
    rows: 10,
    sortOrder: -1,
    sortField: "id",
    // areaid:3,
  };
  const [filter, setFilter] = useState(cusFilter);
  //
  const toggleDialog = () => {
    setShowDialog(!showDialog);
  };
  const editDialogHandler = (data) => {
    setPayload({
      id: data.id,
      deviceid: data.deviceid,
      oldarea: data.area,
      areaid: data.areaid,
      name: data.name,
      contact: data.contact,
      statusid: data.statusid,
      asbutton: data.asbutton,
    });
    toggleDialog();
    setEditMode(true);
  };
  const submitHandler = (payL) => {
    if (payL.areaid === undefined || payL.areaid === "" || payL.areaid === 0) {
      toast("Please select Area");
    } else if (payL.name === undefined || payL.name === "") {
      toast("Name is required");
    } else if (
      payL.contact === undefined ||
      (payL.contact === "" && payL.contact.length !== 10)
    ) {
      toast("Invalid Mobile Number.");
    } else if (payL.statusid === "") {
      toast("Please select Status");
    } else {
      // console.log("editmode:", editMode);
      // console.log(payL);
      updaeAPIHandler(payL);
      toggleDialog();
      handleShowDataTable(false);
    }
  };
  const updaeAPIHandler = (payL) => {
    updateApprovedDevice(payL)
      .then((response) => {
        if (response.data.status === "success") {
          toast.success(response.data.msg);
          resetForm();
        } else if (response.data.status === "warning") {
          swal("Already Registered !", response.data.msg, "warning");
        } else if (response.data.status === "error") {
          swal("Error.?", response.data.msg, "error");
        } else if (response.data.status === "logout") {
          logout();
          navigate("/login");
        } else {
          toast.error(
            "Error in Registering, Something went happned. " +
              response.data.status
          );
        }
        handleShowDataTable(true);
      })
      .catch((res) => {
        navigate("/dashboard");
        toast.error("Catch Error, Something went happned");
      });
  };
  const onLoad = (filterVal) => {
    getDeviceApprovedData(filterVal).then((res) => {
      let dta = res.data.data;
      // console.log(res.data);
      dta.map((el, key) => {
        let action = (getRole() <= 3 || getRole() == 6) && (
          <div style={{ width: "180px" }}>
            {/* Edit Handler Start*/}
            {getRole() <= 3 && (
              <OverlayTrigger
                placement="top"
                delay={{ show: 200, hide: 200 }}
                overlay={
                  <Tooltip id="button-tooltip" {...props}>
                    Edit
                  </Tooltip>
                }
              >
                <span
                  className="pt-0 pb-0"
                  style={{
                    marginRight: "10px",
                    color: "#15A362",
                    cursor: "pointer",
                  }}
                  onClick={() => editDialogHandler(el)}
                >
                  <span className="fas fa-edit"></span>
                </span>
              </OverlayTrigger>
            )}
            {(getRole() <= 3 || getRole() == 6) && (
              <OverlayTrigger
                placement="top"
                delay={{ show: 200, hide: 200 }}
                overlay={
                  <Tooltip id="button-tooltip" {...props}>
                    Reject
                  </Tooltip>
                }
              >
                <span>
                  <DialogDeleteData
                    id={el.id}
                    filter={filter}
                    lazyLoad={onLoad}
                    whatsDel={"deletedevice"}
                  />
                </span>
              </OverlayTrigger>
            )}
          </div>
        );
        dta[key].action = action;
        return 0;
      });
      setTableData(dta);
      setTotalRows(res.data.total);
      setFilter(filterVal);
    });
  };
  const resetForm = () => {
    setPayload({ ...defaultPayload });
  };
  const handleShowDataTable = (callbackval) => {
    setShowDataTable(callbackval);
  };
  //
  return (
    <div className="app-content pt-3 p-md-3 p-lg-4">
      <div className="container-xl">
        <h1 className="app-page-title">Approved Devices</h1>
        <div className="row">
          <DialogApproveDevice
            editmode={editMode}
            isVisible={showDialog}
            toggleVisible={toggleDialog}
            payload={payload}
            onSubmit={submitHandler}
          />
        </div>
        <div className="row">
          <div className="col-12 app-card-settings app-card pt-3 pb-3">
            <div
              className="col-md-2"
              style={{ float: "left", paddingLeft: 3, paddingRight: 3 }}
            >
              <button
                className="btn app-btn-secondary"
                style={{ width: "100%" }}
                onClick={() => {
                  navigate("/pending-devices");
                }}
              >
                <i className="fa fa-share" aria-hidden="true"></i> Show Pending
                Devices
              </button>
            </div>
          </div>
        </div>
        <div className="row" style={{ height: "10px" }}></div>
        {showDataTable ? (
          <div className="row">
            <div className="col-12 app-card-settings app-card pt-1 pb-3">
              <Datatable
                options={options}
                columns={columns}
                filter={filter}
                data={tableData}
                totalRows={totalRows}
                lazyLoad={onLoad}
              ></Datatable>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};
export default ViewDeviceApproved;
