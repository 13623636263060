export const setUserData = (value) => {
  setData("userData", value);
};

const setData = (key, value) => {
  localStorage.setItem(key, value);
};

export const getUserData = () => {
  let user = {};
  if (localStorage.getItem("userData")) {
    user = JSON.parse(localStorage.getItem("userData"));
  }
  return user;
};

export const getUsername = () => {
  let user = getUserData();
  return user.username;
};
export const getName = () => {
  let user = getUserData();
  return user.name;
};

export const getRole = () => {
  let user = getUserData();
  return user.role;
};
export const getAreaName = () => {
  let user = getUserData();
  return user.areaname;
};
export const getAreaId = () => {
  let user = getUserData();
  return user.area; // return area id
};

export const logout = () => {
  localStorage.clear();
  window.location.reload(true);
};

// function clearStorage() {
//     let session = sessionStorage.getItem("login");

//     if (session == null || session === false) {
//         localStorage.removeItem("userData");
//         // localStorage.clear();
//     }
//     // sessionStorage.setItem("login", true);
// }
// window.addEventListener("load", clearStorage);
