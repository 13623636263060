import React, { useState, useEffect } from "react";
import { getRole, getAreaId } from "../../services/AuthService";
import { toast } from "react-toastify";
import swal from "sweetalert";
import {
  getAreaList,
  getPlaces4mAreaId,
  getCentre4mPlaceId,
  getMCDeptList,
  getSewadards4mPlaceId,
  assignDepartment,
  updateAssignedDept,
  getDeptContactDetails,
  getPlaceById,
} from "../../services/HTTPService";
import { logout } from "../../services/AuthService";
import { useNavigate } from "react-router-dom";
import { validations } from "../../services/ValidationService";
import ViewDirWithDept from "./ViewDirWithDept";
import { useParams } from "react-router-dom";

//
export default function AssignDeptLocalArea() {
  const updatekey = useParams().id;
  const navigate = useNavigate();
  const myprops = {
    showAddBtn: false,
  };
  const defaultfields = {
    updatekey: updatekey ? updatekey : "",
    areaid: "",
    area: "",
    centreid: "",
    centre: "",
    placetypeid: "",
    placetype: "",
    placeid: "",
    place: "",
    department: "",
    departmentid: "",
    name: "",
    sewadar: "",
    sewadarid: "",
    sewaareaid: "",
    priority: 0,
  };
  const [loading, setLoading] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [fieldsData, setFieldsData] = useState(defaultfields);
  const [area, setArea] = useState([]);
  const [place, setPlace] = useState([]);
  const [centre, setCentre] = useState([]);
  const [department, setDepartment] = useState([]);
  const [sewadars, setSewadars] = useState([]);
  const [showDataTable, setShowDataTable] = useState(true);
  const [placeid, setPlaceId] = useState(0);
  //
  const getArea = () => {
    if (loading === true) {
      setLoading(false);
      getAreaList().then((response) => {
        if (response.status === 200) {
          setArea(response.data);
        } else {
          navigate(`/dashboard`);
          console.log(response.data);
          toast.error(response.data.msg);
        }
      });
    }
  };
  const getDepartment = () => {
    getMCDeptList().then((response) => {
      if (response.status === 200) {
        setDepartment(response.data);
      } else {
        navigate(`/dashboard`);
        console.log(response.data);
        toast.error(response.data.msg);
      }
    });
  };
  useEffect(() => {
    getArea();
    getDepartment();
  }, []);
  //
  const onChangeArea = (val) => {
    getPlaces4mAreaId({ areaid: val }).then((response) => {
      if (response.status === 200) {
        if (response.data.status === "success") {
          // console.log(response.data);
          setPlace(response.data.data);
        } else {
          // console.log(response.data.msg);
          toast.error(response.data.msg);
        }
      } else {
        console.log("error");
        navigate(`/dashboard`);
      }
    });
  };
  const onChangePlace = (val) => {
    getCentre4mPlaceId({ placeid: val }).then((response) => {
      if (response.status === 200) {
        if (response.data.status === "success") {
          setCentre(response.data.data);
        } else {
          toast.error(response.data.msg);
        }
      } else {
        console.log("error");
        navigate(`/dashboard`);
      }
    });
    getSewadards4mPlaceId({ placeid: val }).then((response) => {
      if (response.status === 200) {
        if (response.data.status === "success") {
          // console.log(response.data);
          setSewadars(response.data.data);
        } else {
          console.log(response.data.msg);
          toast.error(response.data.msg);
        }
      } else {
        console.log("error");
        navigate(`/dashboard`);
      }
    });
  };
  const updateFieldsData = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "areaid":
        setFieldsData({ ...fieldsData, [name]: value });
        if (value !== "") {
          onChangeArea(value);
        }
        break;
      case "placeid":
        setFieldsData({ ...fieldsData, [name]: value });
        if (value !== "") {
          onChangePlace(value);
        }
        break;
      case "priority":
        if (value === "" || validations.numberOnly(value)) {
          setFieldsData({ ...fieldsData, [name]: value });
        }
        break;
      default:
        setFieldsData({ ...fieldsData, [name]: value });
        break;
    }
  };
  const checkValidation = () => {
    // console.log(fieldsData);
    if (fieldsData.areaid === "") {
      toast("Please select Area");
    } else if (fieldsData.placeid === "") {
      toast("Please select Place");
    } else if (fieldsData.sewadarid === "") {
      toast("Please select Sewadar");
    } else if (fieldsData.departmentid === "") {
      toast("Please select Department");
    } else {
      if (!editMode) {
        assignDepartment(fieldsData)
          .then((response) => {
            if (response.data.status === "success") {
              console.log(response.data);
              toast.success(response.data.msg);
            } else if (response.data.status === "warning") {
              console.log(response.data);
              swal("Already Registered !", response.data.msg, "warning");
            } else if (response.data.status === "error") {
              console.log(response.data);
              swal("Error.?", response.data.msg, "error");
            } else if (response.data.status === "logout") {
              logout();
              navigate("/login");
            } else {
              toast.error(
                "Error in Registering, Something went happned. " +
                  response.data.status
              );
            }
            setShowDataTable(true);
          })
          .catch((res) => {
            navigate("/dashboard");
            toast.error("Error in Registering, Something went happned");
          });
        setShowDataTable(false);
      } else {
        updateAssignedDept(fieldsData)
          .then((response) => {
            if (response.data.status === "success") {
              console.log(response.data);
              toast.success(response.data.msg);
              navigate("/assign-deptl2larea");
              resetForm();
            } else if (response.data.status === "warning") {
              console.log(response.data);
              swal("Already Registered !", response.data.msg, "warning");
            } else if (response.data.status === "error") {
              console.log(response.data);
              swal("Error.?", response.data.msg, "error");
            } else if (response.data.status === "logout") {
              logout();
              navigate("/login");
            } else {
              toast.error(
                "Error in Updating, Something went happned. " +
                  response.data.status
              );
            }
            setShowDataTable(true);
          })
          .catch((res) => {
            navigate("/dashboard");
            toast.error("Updating catch Error, Something went happned");
          });
        setShowDataTable(false);
      }
    }
  };
  const getDeptUpdationDetails = () => {
    getDeptContactDetails({ updatekey: updatekey }).then((response) => {
      if (response.status === 200) {
        console.log("Update data :", response.data.data);
        if (response.data.status === "success") {
          setFieldsData({
            ...response.data.data,
            ["updatekey"]: updatekey,
          });
        } else {
          toast.error(response.data.msg);
        }
      } else {
        console.log("error");
        navigate(`/dashboard`);
      }
    });
  };
  useEffect(() => {
    if (updatekey) {
      setEditMode(true);
      getPlace(fieldsData.sewadarid);
      getDeptUpdationDetails();
    }
  }, [updatekey, fieldsData.sewadarid]);

  const getPlace = (updatekey) => {
    setLoading(true);
    getPlaceById({ sewadarid: updatekey }).then((response) => {
      if (response.status === 200) {
        if (response.data.status === "success") {
          console.log("getplace:", response.data.data);
          setPlace(response.data.data);
          setPlaceId(response.data.data[0]["id"]);
          // console.log("Place", response.data.data[0]['id']); //placeid
          // onChangePlace(response.data.data[0]["id"]);
          setLoading(false);
        } else {
          console.log(response.data.msg);
          toast.error(response.data.msg);
        }
      } else {
        console.log("error");
        navigate(`/dashboard`);
      }
      setLoading(false);
    });
  };
  useEffect(() => {
    if (placeid) {
      onChangePlace(placeid);
    }
  }, [placeid]);
  //
  const resetForm = () => {
    setFieldsData({ ...defaultfields });
  };

  console.log("formsDat: ", fieldsData);
  //
  return (
    <>
      <div className="app-content pt-3 p-md-3 p-lg-4">
        <div className="container-xl">
          <h1 className="app-page-title">ASSIGN DEPT. (LOCAL AREA)</h1>
          <span>
            Department Assign for Local Sewadars to Local Area: (*) field is
            mandatory
          </span>
          <div className="row">
            <div className="col-12 app-card-settings app-card pt-3 pb-3">
              <div className="row mb-4">
                <div className="col-md-4 col-12">
                  <label className="form-label" htmlFor="">
                    Area <span style={{ color: "red" }}>*</span>
                  </label>
                  <select
                    className="form-select"
                    name="areaid"
                    value={fieldsData.areaid}
                    onChange={updateFieldsData}
                    disabled={editMode === true ? true : false}
                  >
                    <option value="">Select Area</option>
                    {area.map((val, key) => {
                      return (
                        <>
                          {getAreaId() === val.id ? (
                            <option key={key} value={val.id}>
                              {val.name}
                            </option>
                          ) : null}
                        </>
                        // <>
                        //   {getRole() <= 2 ? (
                        //     <option key={key} value={val.id}>
                        //       {val.name}
                        //     </option>
                        //   ) : getAreaId() === val.id ? (
                        //     <option key={key} value={val.id}>
                        //       {val.name}
                        //     </option>
                        //   ) : null}
                        // </>
                      );
                    })}
                  </select>
                </div>
                <div className="col-md-4 col-12">
                  <label className="form-label" htmlFor="">
                    C/SC/SP <span style={{ color: "red" }}>*</span>
                  </label>
                  <select
                    className="form-select"
                    name="placeid"
                    value={fieldsData.placeid}
                    onChange={updateFieldsData}
                    disabled={editMode === true ? true : false}
                  >
                    <option value="">Select Place</option>
                    {place.map((val, key) => {
                      return (
                        <option key={key} value={val.id}>
                          {val.name + " (" + val.placetype + ")"}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="col-md-4 col-12">
                  <label className="form-label" htmlFor="">
                    Attached Centre
                  </label>
                  <select
                    className="form-select"
                    name="centreid"
                    value={fieldsData.centreid}
                    // onChange={""}
                    disabled
                  >
                    {centre.map((val, key) => {
                      return (
                        <option key={key} value={val.id}>
                          {val.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-md-4 col-12">
                  <label className="form-label" htmlFor="">
                    Sewadar
                  </label>
                  <select
                    className="form-select"
                    name="sewadarid"
                    value={fieldsData.sewadarid}
                    onChange={updateFieldsData}
                    disabled={editMode === true ? true : false}
                  >
                    <option value="">Select Sewadar</option>
                    {sewadars.map((val, key) => {
                      return (
                        <option key={key} value={val.id}>
                          {val.name} [ {val.contact1 + ": " + val.designation}]
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="col-md-4 col-12">
                  <label className="form-label" htmlFor="">
                    Department
                  </label>
                  <select
                    className="form-select"
                    name="departmentid"
                    value={fieldsData.departmentid}
                    onChange={updateFieldsData}
                  >
                    <option value="">Select Department</option>
                    {department.map((val, key) => {
                      return (
                        <option key={key} value={val.id}>
                          {val.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="col-md-4 col-12">
                  <label className="form-label" htmlFor="">
                    Priority
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    name="priority"
                    maxLength={3}
                    value={fieldsData.priority}
                    onChange={updateFieldsData}
                    placeholder="Priority"
                  />
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-md-4 col-12"></div>
                <div className="col-md-4" style={{ float: "right" }}>
                  <button
                    className="btn app-btn-primary"
                    style={{ width: "100%" }}
                    onClick={checkValidation}
                  >
                    {" "}
                    {editMode === true ? "Update" : "Assign Department"}
                  </button>
                </div>
                <div className="col-md-4 col-12"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showDataTable ? <ViewDirWithDept myprops={myprops} /> : null}
    </>
  );
}
