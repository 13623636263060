import React, { useState } from "react";
import { getUsersData } from "../../services/HTTPService";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Datatable from "../../components/Shared/Datatable/Datatable";
import DialogDeleteData from "../../components/Shared/DialogsBoxes/DialogDeleteData";
//
const ViewUsers = (props) => {
  const navigate = useNavigate();
  const [tableData, setTableData] = useState("");
  const [totalRows, setTotalRows] = useState(0);
  const [showDataTable, setShowDataTable] = useState(true);
  //
  const columns = [
    {
      name: "id",
      title: "SNo",
      sorting: true,
    },
    {
      name: "uname",
      title: "Username",
      sorting: true,
    },
    {
      name: "name",
      title: "Name",
      sorting: true,
    },
    {
      name: "email",
      title: "Email",
      sorting: false,
    },
    {
      name: "contact",
      title: "Contact",
      sorting: false,
    },
    {
      name: "role",
      title: "Role",
      sorting: true,
    },
    {
      name: "place",
      title: "Place",
      sorting: true,
    },
    {
      name: "designation",
      title: "Designation",
      sorting: true,
    },
    {
      name: "status",
      title: "Status",
      sorting: true,
    },
    {
      name: "action",
      title: "Action",
      sorting: false,
    },
  ];
  const options = {
    theme: "#15a362",
    rows: [5, 10, 20, 50, 100],
    pagination: true,
    globalFilter: true,
  };
  const cusFilter = {
    page: 1,
    globalFilter: "",
    rows: 10,
    sortOrder: -1,
    sortField: "id",
    // areaid:3,
  };
  const [filter, setFilter] = useState(cusFilter);
  //
  const editDialogHandler = (id) => {
    navigate(`/new-user/${id}`);
  };
  //
  const onLoad = (filterVal) => {
    getUsersData(filterVal).then((res) => {
      let dta = res.data.data;
      dta.map((el, key) => {
        let action = (
          <div style={{ width: "180px" }}>
            {/* Edit Handler Start*/}
            <OverlayTrigger
              placement="top"
              delay={{ show: 200, hide: 200 }}
              overlay={
                <Tooltip id="button-tooltip" {...props}>
                  Edit
                </Tooltip>
              }
            >
              <span
                className="pt-0 pb-0"
                style={{
                  marginRight: "10px",
                  color: "#15A362",
                  cursor: "pointer",
                }}
                onClick={() => editDialogHandler(el.id)}
              >
                <span className="fas fa-edit"></span>
              </span>
            </OverlayTrigger>
            {/* Edit Handler End*/}
            {/* Delete Handler Start */}
            <OverlayTrigger
              placement="top"
              delay={{ show: 200, hide: 200 }}
              overlay={
                <Tooltip id="button-tooltip" {...props}>
                  Delete
                </Tooltip>
              }
            >
              <span>
                <DialogDeleteData
                  id={el.id}
                  filter={filter}
                  lazyLoad={onLoad}
                  whatsDel={"users"}
                />
              </span>
            </OverlayTrigger>
            {/* Delete Handler End */}
          </div>
        );
        dta[key].action = action;
        return 0;
      });
      setTableData(dta);
      setTotalRows(res.data.total);
      setFilter(filterVal);
    });
  };
  //
  return (
    <div className="app-content pt-3 p-md-3 p-lg-4">
      <div className="container-xl">
        <h1 className="app-page-title">USER'S LIST</h1>
        <div className="row">
          {props.myprops.showAddBtn ? (
            <div className="col-12 app-card-settings app-card pt-3 pb-3">
              <div
                className="col-md-2"
                style={{ float: "left", paddingLeft: 3, paddingRight: 3 }}
              >
                <button
                  className="btn app-btn-primary"
                  style={{ width: "100%" }}
                  onClick={() => {
                    navigate("/new-user");
                  }}
                >
                  <span className="fa fa-plus-circle"></span> Add New User
                </button>
              </div>
            </div>
          ) : null}
        </div>
        <div className="row" style={{ height: "10px" }}></div>
        {showDataTable ? (
          <div className="row">
            <div className="col-12 app-card-settings app-card pt-1 pb-3">
              <Datatable
                options={options}
                columns={columns}
                filter={filter}
                data={tableData}
                totalRows={totalRows}
                lazyLoad={onLoad}
              ></Datatable>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};
export default ViewUsers;
