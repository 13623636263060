import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import swal from "sweetalert";
import { logout } from "../../../services/AuthService";
import { delDataByID, delContact } from "../../../services/HTTPService";
//
export default function DialogDeleteData(props) {
  const filter = props.filter;
  const lazyLoad = props.lazyLoad;
  var swalTitle = "Are you sure ?";
  var swalText =
    "Once deleted, you will not be able to recover this imaginary data !";

  const tablename = {
    table: "",
  };

  const deleteData = (id) => {
    //
    switch (props.whatsDel) {
      case "directory":
        tablename.table = delContact;
        break;
      case "area":
      case "centre":
      case "place":
      case "department":
      case "designation":
      case "roles":
      case "users":
      case "directorydept":
        tablename.table = delDataByID;
        break;
      case "deletedevice":
        swalTitle = "Reject !";
        swalText = "Are You Sure, You want to Reject this User ?";
        tablename.table = delDataByID;
        break;
      default:
        break;
    }
    //
    swal({
      title: swalTitle,
      text: swalText,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        tablename
          .table({ id: id, table: props.whatsDel })
          .then((response) => {
            if (response.data.status === "success") {
              // swal("Data Deleted", response.data.msg, "success");
              toast.success(response.data.msg);
              lazyLoad(filter);
            } else if (response.data.status === "warning") {
              toast.warning(response.data.msg);
            } else if (response.data.status === "error") {
              toast.error(response.data.msg);
            } else if (response.data.status === "logout") {
              logout();
            } else {
              toast.error(
                "Error in Registering, Something went happned. " +
                  response.data.status
              );
            }
          })
          .catch((res) => {
            console.log("Error in deletion");
          });
      } else {
        console.log("Your Data is safe and not delete.!");
        // toast("Your Data is safe and not delete.!");
      }
    });
  };

  return (
    <>
      {props.id ? (
        <span
          className="pt-0 pb-0"
          style={{
            marginRight: "10px",
            color: "#EA5C2B",
            cursor: "pointer",
          }}
          onClick={() => deleteData(props.id)}
        >
          <span className="fas fa-minus-circle"></span>
        </span>
      ) : null}
    </>
  );
}
