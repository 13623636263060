import React, { useState } from "react";
import { getRole } from "../../services/AuthService";
import {
  getDevicePendingData,
  approveDevice,
  deleteDevice,
} from "../../services/HTTPService";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { logout } from "../../services/AuthService";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import swal from "sweetalert";
import Datatable from "../../components/Shared/Datatable/Datatable";
import DialogApproveDevice from "../../components/Shared/DialogsBoxes/DialogApproveDevice";
import DialogDelete from "../../components/Shared/DialogsBoxes/DialogDelete";
//
const ViewDevicePending = (props) => {
  const navigate = useNavigate();
  const defaultPayload = {
    id: "",
    name: "",
    contact: "",
    approvedby: "",
    deviceid: "",
    attempt: "",
    updatedat: "",
  };
  const [editMode, setEditMode] = useState(false);
  const [deleteMode, setDeleteMode] = useState(false);

  const [showDialog, setShowDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const [tableData, setTableData] = useState("");
  const [totalRows, setTotalRows] = useState(0);
  const [showDataTable, setShowDataTable] = useState(true);

  const [payload, setPayload] = useState(defaultPayload);
  const [deletePayload, setDeletePayload] = useState(defaultPayload);
  //
  const columns = [
    {
      name: "id",
      title: "SNo",
      sorting: true,
    },
    {
      name: "deviceid",
      title: "DeviceID",
      sorting: true,
    },
    {
      name: "model",
      title: "Model",
      sorting: true,
    },
    {
      name: "name",
      title: "Name",
      sorting: true,
    },
    {
      name: "mobile",
      title: "Mobile",
      sorting: true,
    },
    {
      name: "attempt",
      title: "Attempt",
      sorting: true,
    },
    {
      name: "updatedat",
      title: "Last Update",
      sorting: true,
    },
    (getRole() <= 3 || getRole() == 6) && {
      name: "action",
      title: "Action",
      sorting: false,
    },
  ];
  const options = {
    theme: "#15a362",
    rows: [5, 10, 20, 50, 100],
    pagination: true,
    globalFilter: true,
  };
  const cusFilter = {
    page: 1,
    globalFilter: "",
    rows: 10,
    sortOrder: -1,
    sortField: "id",
    // areaid:3,
  };
  const [filter, setFilter] = useState(cusFilter);
  //
  const toggleDialog = () => {
    setShowDialog(!showDialog);
  };
  const toggleDeleteDialog = () => {
    setShowDeleteDialog(!showDeleteDialog);
  };
  const editDialogHandler = (data) => {
    setPayload({
      id: data.id,
      deviceid: data.deviceid,
      name: data.name,
      contact: data.mobile,
      areaid: 0, // default Area 0 mean NO Area Selected
      statusid: 1, // default Status is Active
    });
    toggleDialog();
    setEditMode(false);
  };
  const deleteDialogHandler = (data) => {
    setDeletePayload({
      id: data.id,
      deviceid: data.deviceid,
      name: data.name,
      contact: data.mobile,
    });
    toggleDeleteDialog();
    setDeleteMode(false);
  };

  const submitHandler = (payL) => {
    if (payL.areaid === undefined || payL.areaid === "" || payL.areaid === 0) {
      toast("Please select Area");
    } else if (payL.name === undefined || payL.name === "") {
      toast("Name is required");
    } else if (
      payL.contact === undefined ||
      (payL.contact === "" && payL.contact.length !== 10)
    ) {
      toast("Invalid Mobile Number.");
    } else if (payL.statusid === undefined || payL.statusid === "") {
      toast("Please select Status");
    } else {
      console.log("editmode:", editMode);
      console.log(payL);
      addAPIHandler(payL);
      toggleDialog();
      handleShowDataTable(false);
    }
  };
  const submitDeleteHandler = (payL) => {
    if (payL.id === undefined || payL.areaid === "" || payL.areaid === 0) {
      toast("Undentified");
    } else {
      deleteDevice(payL)
        .then((response) => {
          if (response.data.status === "success") {
            toast.success(response.data.msg);
            resetForm();
          } else if (response.data.status === "warning") {
            swal("Device Not Found !", response.data.msg, "warning");
          } else if (response.data.status === "error") {
            swal("Error.?", response.data.msg, "error");
          } else if (response.data.status === "logout") {
            logout();
            navigate("/login");
          } else {
            toast.error(
              "Error, Something went happned. " + response.data.status
            );
          }
          handleShowDataTable(true);
        })
        .catch((res) => {
          navigate("/dashboard");
          toast.error("Catch Error, Something went happned");
        });
      toggleDeleteDialog();
      handleShowDataTable(false);
    }
  };
  const addAPIHandler = (payL) => {
    approveDevice(payL)
      .then((response) => {
        if (response.data.status === "success") {
          toast.success(response.data.msg);
          resetForm();
        } else if (response.data.status === "warning") {
          swal("Already Registered !", response.data.msg, "warning");
        } else if (response.data.status === "error") {
          swal("Error.?", response.data.msg, "error");
        } else if (response.data.status === "logout") {
          logout();
          navigate("/login");
        } else {
          toast.error("Error, Something went happned. " + response.data.status);
        }
        handleShowDataTable(true);
      })
      .catch((res) => {
        navigate("/dashboard");
        toast.error("Catch Error, Something went happned");
      });
  };

  const onLoad = (filterVal) => {
    getDevicePendingData(filterVal).then((res) => {
      let dta = res.data.data;
      // console.log(res.data);
      dta.map((el, key) => {
        let action = (getRole() <= 3 || getRole() == 6) && (
          <div style={{ width: "180px" }}>
            {/* Edit Handler Start*/}
            <OverlayTrigger
              placement="top"
              delay={{ show: 200, hide: 200 }}
              overlay={
                <Tooltip id="button-tooltip" {...props}>
                  Approve
                </Tooltip>
              }
            >
              <button
                className="btn app-btn-primary"
                style={{ width: "48%" }}
                onClick={() => editDialogHandler(el)}
              >
                Approve
              </button>
            </OverlayTrigger>
            {/* Edit Handler End*/}
            {getRole() <= 2 && (
              <OverlayTrigger
                placement="top"
                delay={{ show: 200, hide: 200 }}
                overlay={
                  <Tooltip id="button-tooltip" {...props}>
                    Delete
                  </Tooltip>
                }
              >
                <button
                  className="btn app-btn-danger"
                  style={{ width: "48%", marginLeft: 2 }}
                  onClick={() => deleteDialogHandler(el)}
                >
                  Delete
                </button>
              </OverlayTrigger>
            )}
          </div>
        );
        dta[key].action = action;
        return 0;
      });
      setTableData(dta);
      setTotalRows(res.data.total);
      setFilter(filterVal);
    });
  };
  const resetForm = () => {
    setPayload({ ...defaultPayload });
    setDeletePayload({ ...defaultPayload });
  };
  const handleShowDataTable = (callbackval) => {
    setShowDataTable(callbackval);
  };
  //
  return (
    <div className="app-content pt-3 p-md-3 p-lg-4">
      <div className="container-xl">
        <h1 className="app-page-title">Pending Devices</h1>
        <div className="row">
          <DialogApproveDevice
            editmode={editMode}
            isVisible={showDialog}
            toggleVisible={toggleDialog}
            payload={payload}
            onSubmit={submitHandler}
          />
          <DialogDelete
            deleteMode={deleteMode}
            isVisible={showDeleteDialog}
            toggleVisible={toggleDeleteDialog}
            payload={deletePayload}
            onSubmit={submitDeleteHandler}
          />
        </div>
        <div className="row">
          <div className="col-12 app-card-settings app-card pt-3 pb-3">
            <div
              className="col-md-2"
              style={{ float: "left", paddingLeft: 3, paddingRight: 3 }}
            >
              <button
                className="btn app-btn-secondary"
                style={{ width: "100%" }}
                onClick={() => {
                  navigate("/approved-devices");
                }}
              >
                <i className="fa fa-share" aria-hidden="true"></i> Show Approved
                Devices
              </button>
            </div>
          </div>
        </div>
        <div className="row" style={{ height: "10px" }}></div>
        {showDataTable ? (
          <div className="row">
            <div className="col-12 app-card-settings app-card pt-1 pb-3">
              <Datatable
                options={options}
                columns={columns}
                filter={filter}
                data={tableData}
                totalRows={totalRows}
                lazyLoad={onLoad}
              ></Datatable>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};
export default ViewDevicePending;
