import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import swal from "sweetalert";
import {
  addNewPlace,
  updatePlace,
  getAreaList,
  getPlaceById,
  getPlaceTypeList,
  getCentre4mAreaId,
  getCentre4mPlaceId,
} from "../../services/HTTPService";
import { logout } from "../../services/AuthService";
import { useNavigate, useParams } from "react-router-dom";
import ViewPlaces from "./ViewPlaces";
//
export default function AddPlace() {
  const navigate = useNavigate();
  const updatekey = useParams().id;
  const myprops = {
    showAddBtn: false,
  };
  const defaultfields = {
    updatekey: updatekey ? updatekey : "",
    areaid: "",
    area: "",
    centreid: "",
    centre: "",
    placetypeid: "",
    placetype: "",
    name: "",
  };
  const [loading, setLoading] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [fieldsData, setFieldsData] = useState(defaultfields);
  const [area, setArea] = useState([]);
  const [centre, setCentre] = useState([]);
  const [placeType, setPlaceType] = useState([]);
  const [showDataTable, setShowDataTable] = useState(true);
  //
  const getArea = () => {
    if (loading === true) {
      setLoading(false);
      getAreaList().then((response) => {
        if (response.status === 200) {
          setArea(response.data);
        } else {
          navigate(`/dashboard`);
          console.log(response.data);
          toast.error(response.data.msg);
        }
      });
    }
  };
  const getPlaceType = () => {
    if (loading === true) {
      setLoading(false);
      getPlaceTypeList().then((response) => {
        if (response.status === 200) {
          setPlaceType(response.data);
        } else {
          navigate(`/dashboard`);
          console.log(response.data);
          toast.error(response.data.msg);
        }
      });
    }
  };
  useEffect(() => {
    getArea();
    getPlaceType();
  });
  //
  const onChangeArea = (val) => {
    getCentre4mAreaId({ areaid: val }).then((response) => {
      if (response.status === 200) {
        if (response.data.status === "success") {
          // console.log(response.data);
          setCentre(response.data.data);
        } else {
          // console.log(response.data.msg);
          toast.error(response.data.msg);
        }
      } else {
        console.log("error");
        navigate(`/dashboard`);
      }
    });
  };
  //
  const updateFieldsData = (e) => {
    // console.log(fieldsData);
    const { name, value } = e.target;
    switch (name) {
      case "areaid":
        setFieldsData({ ...fieldsData, [name]: value });
        if (value !== "") {
          onChangeArea(value);
        }
        break;
      default:
        setFieldsData({ ...fieldsData, [name]: value });
        break;
    }
  };
  // console.log(fieldsData);
  const checkValidation = () => {
    if (fieldsData.areaid === "") {
      toast("Please select Area");
    } else if (fieldsData.centreid === "") {
      toast("Please select Centre");
    } else if (fieldsData.placetypeid === "") {
      toast("Please select Place Type");
    } else if (fieldsData.name === "") {
      toast("Place Name is required");
    } else {
      if (!editMode) {
        addNewPlace(fieldsData)
          .then((response) => {
            if (response.data.status === "success") {
              // console.log(response.data);
              toast.success(response.data.msg);
              resetForm();
            } else if (response.data.status === "warning") {
              console.log(response.data);
              swal("Already Registered !", response.data.msg, "warning");
            } else if (response.data.status === "error") {
              console.log(response.data);
              swal("Error.?", response.data.msg, "error");
            } else if (response.data.status === "logout") {
              logout();
              navigate("/login");
            } else {
              toast.error(
                "Error in Registering, Something went happned. " +
                  response.data.status
              );
            }
            setShowDataTable(true);
          })
          .catch((res) => {
            navigate("/dashboard");
            toast.error("Error in Registering, Something went happned");
          });
        setShowDataTable(false);
      } else {
        updatePlace(fieldsData)
          .then((response) => {
            if (response.data.status === "success") {
              // console.log(response.data);
              toast.success(response.data.msg);
              navigate("/add-place");
            } else if (response.data.status === "warning") {
              console.log(response.data);
              swal("Already Registered !", response.data.msg, "warning");
            } else if (response.data.status === "error") {
              console.log(response.data);
              swal("Error.?", response.data.msg, "error");
            } else if (response.data.status === "logout") {
              logout();
              navigate("/login");
            } else {
              toast.error(
                "Error in Updating, Something went happned. " +
                  response.data.status
              );
            }
            // setShowDataTable(true);
          })
          .catch((res) => {
            navigate("/dashboard");
            toast.error("Catch Error, Something went happned");
          });
        // setShowDataTable(false);
      }
    }
  };
  const resetForm = () => {
    setFieldsData({ ...defaultfields });
  };
  //
  const getUpdationDetails = () => {
    //
    getPlaceById({ placeid: updatekey }).then((response) => {
      if (response.status === 200) {
        if (response.data.status === "success") {
          // console.log(response.data.data);
          // alert(response.data.data.qry);
          setFieldsData({
            ...response.data.data[0],
            ["updatekey"]: updatekey,
          });
        } else {
          toast.error(response.data.msg);
        }
      } else {
        console.log("error");
        navigate(`/dashboard`);
      }
    });
    //
    getCentre4mPlaceId({ placeid: updatekey }).then((response) => {
      if (response.status === 200) {
        if (response.data.status === "success") {
          // console.log(response.data);
          setCentre(response.data.data);
        } else {
          // console.log(response.data.msg);
          toast.error(response.data.msg);
        }
      } else {
        console.log("error");
        navigate(`/dashboard`);
      }
    });
    //
  };
  useEffect(() => {
    // console.log(updatekey);
    if (updatekey) {
      setEditMode(true);
      getUpdationDetails();
    }
  }, [updatekey]);
  //
  return (
    <>
      <div className="app-content pt-3 p-md-3 p-lg-4">
        <div className="container-xl">
          <h1 className="app-page-title">Satsang Place</h1>
          <div className="row">
            <div className="col-12 app-card-settings app-card pt-3 pb-3">
              <div className="row mb-4">
                <div className="col-md-3 col-12">
                  <label className="form-label" htmlFor="">
                    Area <span style={{ color: "red" }}>*</span>
                  </label>
                  <select
                    className="form-select"
                    name="areaid"
                    value={fieldsData.areaid}
                    onChange={updateFieldsData}
                  >
                    <option value="">Select Area</option>
                    {area.map((val, key) => {
                      return (
                        <option key={key} value={val.id}>
                          {val.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="col-md-3 col-12">
                  <label className="form-label" htmlFor="">
                    Attached Centre <span style={{ color: "red" }}>*</span>
                  </label>
                  <select
                    className="form-select"
                    name="centreid"
                    value={fieldsData.centreid}
                    onChange={updateFieldsData}
                  >
                    <option value="">Select Centre</option>
                    {centre.map((val, key) => {
                      return (
                        <option key={key} value={val.id}>
                          {val.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="col-md-3 col-12">
                  <label className="form-label" htmlFor="">
                    Place Type <span style={{ color: "red" }}>*</span>
                  </label>
                  <select
                    className="form-select"
                    name="placetypeid"
                    value={fieldsData.placetypeid}
                    onChange={updateFieldsData}
                  >
                    <option value="">Select Type</option>
                    {placeType.map((val, key) => {
                      return (
                        <>
                          {editMode === true ? (
                            <option key={key} value={val.id}>
                              {val.name}
                            </option>
                          ) : val.id !== 1 ? (
                            <option key={key} value={val.id}>
                              {val.name}
                            </option>
                          ) : null}
                        </>
                      );
                    })}
                  </select>
                </div>
                <div className="col-md-3 col-12">
                  <label className="form-label">
                    Name <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    value={fieldsData.name}
                    onChange={updateFieldsData}
                    placeholder="Name"
                  />
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-md-4 col-12"></div>
                <div className="col-md-4" style={{ float: "right" }}>
                  <button
                    className="btn app-btn-primary"
                    style={{ width: "100%" }}
                    onClick={checkValidation}
                  >
                    {" "}
                    {editMode === true ? "Update" : "Add New Place"}
                  </button>
                </div>
                <div className="col-md-4 col-12"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showDataTable ? <ViewPlaces myprops={myprops} /> : null}
    </>
  );
}
