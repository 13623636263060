import React, { useState } from "react";
import { getRole } from "../../services/AuthService";
import { getPlacesData } from "../../services/HTTPService";
import Datatable from "../../components/Shared/Datatable/Datatable";
import { useNavigate } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { toast } from "react-toastify";
import DialogDeleteData from "../../components/Shared/DialogsBoxes/DialogDeleteData";
//
const ViewPlaces = (props) => {
  // console.log("ViewPlace-",props);
  const navigate = useNavigate();
  const [placeData, setPlaceData] = useState("");
  const [totalRows, setTotalRows] = useState(0);
  const columns = [
    {
      name: "id",
      title: "SNo",
      sorting: true,
    },
    {
      name: "placetype",
      title: "Type",
      sorting: true,
    },
    {
      name: "name",
      title: "Place Name",
      sorting: true,
    },
    {
      name: "centre",
      title: "Attached Centre",
      sorting: true,
    },
    {
      name: "area",
      title: "Attached Area",
      sorting: true,
    },
    getRole() <= 2 && {
      name: "action",
      title: "Action",
      sorting: false,
    },
  ];
  const options = {
    theme: "#15a362",
    rows: [5, 10, 20, 50, 100],
    pagination: true,
    globalFilter: true,
  };
  const cusFilter = {
    page: 1,
    globalFilter: "",
    rows: 10,
    sortOrder: -1,
    sortField: "id",
    // areaid:3,
  };
  const [filter, setFilter] = useState(cusFilter);
  const editPlaceHandler = (id) => {
    navigate(`/add-place/${id}`);
  };
  const onLoad = (filterVal) => {
    getPlacesData(filterVal).then((res) => {
      let dta = res.data.data;
      dta.map((el, key) => {
        let action = (
          <div style={{ width: "180px" }}>
            {/* Edit Handler Start */}
            <OverlayTrigger
              placement="top"
              delay={{ show: 200, hide: 200 }}
              overlay={
                <Tooltip id="button-tooltip" {...props}>
                  Edit
                </Tooltip>
              }
            >
              <span
                className="pt-0 pb-0"
                style={{
                  marginRight: "10px",
                  color: "#1C6DD0",
                  cursor: "pointer",
                }}
                onClick={() => editPlaceHandler(el.id)}
              >
                <span className="fas fa-edit"></span>
              </span>
            </OverlayTrigger>
            {/* Edit Handler End */}
            {/* Delete Handler Start */}
            <OverlayTrigger
              placement="top"
              delay={{ show: 200, hide: 200 }}
              overlay={
                <Tooltip id="button-tooltip" {...props}>
                  Delete
                </Tooltip>
              }
            >
              <span>
                <DialogDeleteData
                  id={el.id}
                  filter={filter}
                  lazyLoad={onLoad}
                  whatsDel={"place"}
                />
              </span>
            </OverlayTrigger>
            {/* Delete Handler End */}
          </div>
        );
        dta[key].action = action;
        return 0;
      });
      setPlaceData(dta);
      setTotalRows(res.data.total);
      setFilter(filterVal);
    });
  };
  return (
    <div className="app-content pt-3 p-md-3 p-lg-4">
      <div className="container-xl">
        <h1 className="app-page-title">View Satsang Place</h1>
        {getRole() <= 2 && (
          <div className="row">
            {props.myprops.showAddBtn && (
              <div className="col-12 app-card-settings app-card pt-3 pb-3">
                <div
                  className="col-md-2"
                  style={{ float: "left", paddingLeft: 3, paddingRight: 3 }}
                >
                  <button
                    type="button"
                    className="btn app-btn-primary"
                    style={{ width: "100%" }}
                    onClick={() => navigate("/add-place")}
                  >
                    <span className="fa fa-plus-circle"></span> Add Place
                    (C/SC/SP)
                  </button>
                </div>
              </div>
            )}
          </div>
        )}
        <div className="row" style={{ height: "10px" }}></div>
        <div className="row">
          <div className="col-12 app-card-settings app-card pt-1 pb-3">
            <Datatable
              options={options}
              columns={columns}
              filter={filter}
              data={placeData}
              totalRows={totalRows}
              lazyLoad={onLoad}
            ></Datatable>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewPlaces;
