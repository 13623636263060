import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getRole, getAreaId } from "../../services/AuthService";
import { toast } from "react-toastify";
import swal from "sweetalert";
import {
  getAreaList,
  getDesignationList,
  getPlaces4mAreaId,
  getCentre4mPlaceId,
  addNewContact,
  updateContact,
  getContactDetails,
  getPlaceById,
} from "../../services/HTTPService";
import { logout } from "../../services/AuthService";
import { useNavigate } from "react-router-dom";
import { validations } from "../../services/ValidationService";
import ViewDirectory from "./ViewDirectory";
//
export default function AddContact() {
  const updatekey = useParams().id;
  const navigate = useNavigate();
  const myprops = {
    showAddBtn: false,
  };
  const defaultfields = {
    updatekey: updatekey ? updatekey : "",
    areaid: "",
    area: "",
    centreid: "",
    centre: "",
    placetypeid: "",
    placetype: "",
    placeid: "",
    place: "",
    designationid: "",
    designation: "",
    name: "",
    gender: "",
    contact1: "",
    contact2: "",
    bloodgroup: "",
    email: "",
    userstatus: 1,
  };
  const [loading, setLoading] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [fieldsData, setFieldsData] = useState(defaultfields);
  const [area, setArea] = useState([]);
  const [place, setPlace] = useState([]);
  const [centre, setCentre] = useState([]);
  const [designation, setDesignation] = useState([]);
  const [showDataTable, setShowDataTable] = useState(true);
  //
  const getArea = () => {
    if (loading === true) {
      setLoading(false);
      getAreaList().then((response) => {
        if (response.status === 200) {
          setArea(response.data);
        } else {
          navigate(`/dashboard`);
          console.log(response.data);
          toast.error(response.data.msg);
        }
      });
    }
  };
  const getDesignation = () => {
    if (loading === true) {
      setLoading(false);
      getDesignationList().then((response) => {
        if (response.status === 200) {
          setDesignation(response.data);
        } else {
          navigate(`/dashboard`);
          console.log(response.data);
          toast.error(response.data.msg);
        }
      });
    }
  };
  useEffect(() => {
    getArea();
    getDesignation();
  }, []);
  //
  const onChangeArea = (val) => {
    getPlaces4mAreaId({ areaid: val }).then((response) => {
      if (response.status === 200) {
        if (response.data.status === "success") {
          console.log(response.data);
          setPlace(response.data.data);
        } else {
          console.log(response.data.msg);
          toast.error(response.data.msg);
        }
      } else {
        console.log("error");
        navigate(`/dashboard`);
      }
    });
  };
  const onChangePlace = (val) => {
    getCentre4mPlaceId({ placeid: val }).then((response) => {
      if (response.status === 200) {
        if (response.data.status === "success") {
          // console.log(response.data);
          setCentre(response.data.data);
        } else {
          // console.log(response.data.msg);
          toast.error(response.data.msg);
        }
      } else {
        console.log("error");
        navigate(`/dashboard`);
      }
    });
  };
  const getPlace = (updatekey) => {
    getPlaceById({ sewadarid: updatekey }).then((response) => {
      if (response.status === 200) {
        if (response.data.status === "success") {
          setPlace(response.data.data);
          // console.log("Place", response.data.data[0]['id']); //placeid
          onChangePlace(response.data.data[0]["id"]);
        } else {
          console.log(response.data.msg);
          toast.error(response.data.msg);
        }
      } else {
        console.log("error");
        navigate(`/dashboard`);
      }
    });
  };
  //
  const updateFieldsData = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "areaid":
        setFieldsData({ ...fieldsData, [name]: value });
        if (value !== "") {
          onChangeArea(value);
        }
        break;
      case "placeid":
        setFieldsData({ ...fieldsData, [name]: value });
        if (value !== "") {
          onChangePlace(value);
        }
        break;
      case "contact1":
      case "contact2":
        if (value === "" || validations.numberOnly(value)) {
          setFieldsData({ ...fieldsData, [name]: value });
        }
        break;
      default:
        setFieldsData({ ...fieldsData, [name]: value });
        break;
    }
  };
  const checkValidation = () => {
    // console.log(fieldsData);
    if (fieldsData.areaid === "") {
      toast("Please select Area");
    } else if (fieldsData.placeid === "") {
      toast("Please select Place");
    } else if (fieldsData.gender === "") {
      toast("Please select Gender");
    } else if (fieldsData.designationid === "") {
      toast("Please select Designation");
    } else if (fieldsData.name === "") {
      toast("Name is required");
    } else if (
      fieldsData.contact1 === "" ||
      fieldsData.contact1.length !== 10
    ) {
      toast("Invalid Mobile Number-01");
    } else if (
      fieldsData.contact2 !== "" &&
      fieldsData.contact2.length !== 10
    ) {
      toast("Invalid Mobile Number-02");
    } else if (fieldsData.userstatus === "") {
      toast("Please select User Status");
    } else {
      if (!editMode) {
        addNewContact(fieldsData)
          .then((response) => {
            if (response.data.status === "success") {
              console.log(response.data);
              toast.success(response.data.msg);
              resetNewContactForm();
            } else if (response.data.status === "warning") {
              console.log(response.data);
              swal("Already Registered !", response.data.msg, "warning");
            } else if (response.data.status === "error") {
              console.log(response.data);
              swal("Error.?", response.data.msg, "error");
            } else if (response.data.status === "logout") {
              logout();
              navigate("/login");
            } else {
              toast.error(
                "Error in Registering, Something went happned. " +
                  response.data.status
              );
            }
            setShowDataTable(true);
          })
          .catch((res) => {
            navigate("/dashboard");
            toast.error("Error in Registering, Something went happned");
          });
        setShowDataTable(false);
      } else {
        updateContact(fieldsData)
          .then((response) => {
            if (response.data.status === "success") {
              // console.log("updatecontact: ", response.data);
              toast.success(response.data.msg);
              resetForm();
              navigate("/add-contact");
              // window.history.back();
            } else if (response.data.status === "warning") {
              console.log(response.data);
              swal("Already Registered !", response.data.msg, "warning");
            } else if (response.data.status === "error") {
              console.log(response.data);
              swal("Error.?", response.data.msg, "error");
            } else if (response.data.status === "logout") {
              logout();
              navigate("/login");
            } else {
              toast.error(
                "Error in Updating, Something went happned. " +
                  response.data.status
              );
              console.log(response.data);
            }
            // setShowDataTable(true);
          })
          .catch((res) => {
            navigate("/dashboard");
            toast.error("Updating Error, Something went happned");
          });
        // setShowDataTable(false);
      }
    }
  };
  const resetForm = () => {
    setFieldsData({ ...defaultfields });
  };
  const resetNewContactForm = () => {
    // setFieldsData({ ...defaultfields });
    setFieldsData({
      ...fieldsData,
      ["gender"]: "",
      ["name"]: "",
      ["contact1"]: "",
      ["contact2"]: "",
    });
  };
  //
  const getUpdationDetails = () => {
    getContactDetails({ updatekey: updatekey }).then((response) => {
      if (response.status === 200) {
        // console.log("Update data :", response.data.data);
        if (response.data.status === "success") {
          setFieldsData({
            ...response.data.data,
            ["updatekey"]: updatekey,
          });
        } else {
          toast.error(response.data.msg);
        }
      } else {
        console.log("error");
        navigate(`/dashboard`);
      }
    });
  };
  useEffect(() => {
    if (updatekey) {
      setEditMode(true);
      getUpdationDetails();
      getPlace(updatekey);
    }
  }, [updatekey]);
  console.log("formsDat: ", fieldsData);
  //
  return (
    <>
      <div className="app-content pt-3 p-md-3 p-lg-4">
        <div className="container-xl">
          <h1 className="app-page-title">
            {editMode ? "Update Contact Details" : "Add New Contact"}
          </h1>
          <div className="row">
            <div className="col-12 app-card-settings app-card pt-3 pb-3">
              <div className="row mb-4">
                <div className="col-md-3 col-12">
                  <label className="form-label" htmlFor="">
                    Area <span style={{ color: "red" }}>*</span>
                  </label>
                  <select
                    className="form-select"
                    name="areaid"
                    value={fieldsData.areaid}
                    onChange={updateFieldsData}
                  >
                    <option value="">Select Area</option>
                    {area.map((val, key) => {
                      return (
                        <>
                          {getRole() <= 2 ? (
                            <option key={key} value={val.id}>
                              {val.name}
                            </option>
                          ) : getAreaId() === val.id ? (
                            <option key={key} value={val.id}>
                              {val.name}
                            </option>
                          ) : null}
                        </>
                      );
                    })}
                  </select>
                </div>
                <div className="col-md-3 col-12">
                  <label className="form-label" htmlFor="">
                    C/SC/SP <span style={{ color: "red" }}>*</span>
                  </label>
                  <select
                    className="form-select"
                    name="placeid"
                    value={fieldsData.placeid}
                    onChange={updateFieldsData}
                  >
                    <option value="">Select Place</option>
                    {place.map((val, key) => {
                      return (
                        <option key={key} value={val.id}>
                          {val.name + " (" + val.placetype + ")"}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="col-md-3 col-12">
                  <label className="form-label" htmlFor="">
                    Attached Centre
                  </label>
                  <select
                    className="form-select"
                    name="centreid"
                    value={fieldsData.centreid}
                    // onChange={""}
                    disabled
                  >
                    {centre.map((val, key) => {
                      return (
                        <option key={key} value={val.id}>
                          {val.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="col-md-3 col-12">
                  <label className="form-label" htmlFor="">
                    Gender
                  </label>
                  <select
                    className="form-select"
                    name="gender"
                    value={fieldsData.gender}
                    onChange={updateFieldsData}
                  >
                    <option value="">Select Gender</option>
                    <option value="M">Male</option>
                    <option value="F">Female</option>
                    <option value="O">Other</option>
                  </select>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-md-3 col-12">
                  <label className="form-label" htmlFor="">
                    Designation
                  </label>
                  <select
                    className="form-select"
                    name="designationid"
                    value={fieldsData.designationid}
                    onChange={updateFieldsData}
                  >
                    <option value="">Select Designation</option>
                    {designation.map((val, key) => {
                      console.log("SElected Desig:", fieldsData.designationid);
                      return (
                        <option key={key} value={val.id}>
                          {val.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="col-md-3 col-12">
                  <label className="form-label">
                    Name <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    value={fieldsData.name}
                    onChange={updateFieldsData}
                    placeholder="Name"
                  />
                </div>
                <div className="col-md-3 col-12">
                  <label className="form-label" htmlFor="">
                    Contact-01 <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="contact1"
                    maxLength={10}
                    value={fieldsData.contact1}
                    onChange={updateFieldsData}
                    placeholder="Contact-01"
                  />
                </div>
                <div className="col-md-3 col-12">
                  <label className="form-label" htmlFor="">
                    Contact-02
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="contact2"
                    maxLength={10}
                    value={fieldsData.contact2}
                    onChange={updateFieldsData}
                    placeholder="Contact-02"
                  />
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-md-3 col-12">
                  <label className="form-label" htmlFor="">
                    Blood Group
                  </label>
                  <select
                    className="form-select"
                    name="bloodgroup"
                    value={fieldsData.bloodgroup}
                    onChange={updateFieldsData}
                  >
                    <option value="">Select Blood Group</option>
                    <option value="A+">A+</option>
                    <option value="A-">A-</option>
                    <option value="B+">B+</option>
                    <option value="B-">B-</option>
                    <option value="AB+">AB+</option>
                    <option value="AB-">AB-</option>
                    <option value="O+">O+</option>
                    <option value="O-">O-</option>
                  </select>
                </div>
                <div className="col-md-6 col-12">
                  <label className="form-label">Email</label>
                  <input
                    type="text"
                    className="form-control"
                    name="email"
                    value={fieldsData.email}
                    onChange={updateFieldsData}
                    placeholder="Email"
                  />
                </div>
                <div className="col-md-3 col-12">
                  <label className="form-label" htmlFor="">
                    Status <span style={{ color: "red" }}>*</span>
                  </label>
                  <select
                    className="form-select"
                    name="userstatus"
                    value={fieldsData.userstatus}
                    onChange={updateFieldsData}
                  >
                    <option value="">Select Status</option>
                    <option value="1">Active</option>
                    <option value="0">Not Active</option>
                  </select>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-md-4 col-12"></div>
                <div className="col-md-4" style={{ float: "right" }}>
                  <button
                    className="btn app-btn-primary"
                    style={{ width: "100%" }}
                    onClick={checkValidation}
                  >
                    {" "}
                    {editMode === true ? "Update Contact" : "Add New Contact"}
                  </button>
                </div>
                <div className="col-md-4 col-12"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showDataTable ? <ViewDirectory myprops={myprops} /> : null}
    </>
  );
}
