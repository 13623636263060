import React, { useState, useEffect } from "react";
import { getRole } from "../../../services/AuthService";
export default function DialogDelete(props) {
  const defaultPayload = {
    id: 0,
    name: "",
    contact: "",
    deviceid: "",
    updatedat: "",
  };
  const [payload, setPayload] = useState(defaultPayload);
  const toggleVisible = props.toggleVisible;
  const [deleteMode, setDeleteMode] = useState(false);
  //
  console.log("🚀 ~ file: DialogDelete.js:21 ~ DialogDelete ~ props:", props);

  useEffect(() => {
    setPayload(props.payload);
  }, [props.payload]);
  //
  useEffect(() => {
    setDeleteMode(props.deleteMode);
    // console.log("Edit Mode:", props.deleteMode);
  }, [props.deleteMode]);
  //
  const cancelHandler = () => {
    toggleVisible();
  };
  const trigger = () => {
    props.onSubmit(payload);
  };

  const view = () => {
    return (
      <>
        <div className="dialog-container">
          <div className="">
            <h4 style={{ textAlign: "center" }}>{"Delete ?"}</h4>
            <div className="mt-2 mb-2">
              <label className="form-label" htmlFor="">
                Are You sure you want to delete this device ?
              </label>
            </div>
          </div>
          <div className="button-container">
            <button
              className="btn app-btn-secondary"
              onClick={() => cancelHandler()}
            >
              Cancel
            </button>
            {getRole() <= 2 && (
              <>
                <button className="btn app-btn-danger" onClick={trigger}>
                  {"Delete"}
                </button>
              </>
            )}
          </div>
        </div>
        <div className="confirm-bg"></div>
      </>
    );
  };

  return <div>{props.isVisible ? view() : ""}</div>;
}
