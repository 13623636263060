import React, { Suspense, useState } from "react";
import { getUserData } from "./services/AuthService";
import { ToastContainer } from "react-toastify";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AppRoutes from "./routes/Routes";
import Navigation from "./layouts/Navigation";
import Dashboard from "./pages/Dashboard";
import Wrapper from "./hoc/Wrapper";
import Loader from "react-loader-spinner";
import axios from "axios";
import AddContact from "./pages/phonedirectory/AddContact";
import AddPlace from "./pages/satsangplaces/AddPlace";
import AddUser from "./pages/settings/AddUser";
import AssignDeptLocalArea from "./pages/phonedirectory/AssignDeptLocalArea";
import "react-toastify/dist/ReactToastify.css";
//
function App() {
  axios.defaults.baseURL = "https://access.linesoflogic.com/rssbrdr/areadirectory/api/";
  // axios.defaults.baseURL = "http://localhost:8000/rssbdirectoryapis/api";

  const [userData, updateUserData] = useState(getUserData());
  // const [userData, updateUserData] = useState({
  //   username: "admin",
  //   password: "admin",
  // });
  const [loading, setLoading] = useState(false);
  const loaderStyle = {
    position: "absolute",
    width: "100%",
    height: "100%",
    paddingLeft: "40%",
    paddingTop: "40vh",
    zIndex: "100",
    background: "#00000040",
  };

  axios.interceptors.request.use((request) => {
    if (Object.keys(userData).length > 0) {
      const token = userData.authToken;
      request.headers.Authorization = `Bearer ${token}`;
    }

    const spinnerException = [
      "GetConversation",
      "CheckNewMessage",
      "GetRoutes",
      "BookmarkQuestion",
      "ReportQuestion",
    ];
    const flag = spinnerException.every(
      (substring) => request.url.indexOf(substring) === -1
    );

    if (flag) {
      setLoading(true);
    }
    return request;
  });

  axios.interceptors.response.use((response) => {
    setLoading(false);
    return response;
  });

  let checkLoginStatus = () => {
    updateUserData(getUserData);
  };

  const LoginComponent = React.lazy(() => import("./pages/Login"));

  if (Object.keys(userData).length === 0) {
    return (
      <Suspense
        fallback={
          <Loader
            style={loaderStyle}
            type="Circles"
            color="#fff"
            height={80}
            width={80}
          />
        }
      >
        <ToastContainer />
        <Router>
          {loading ? (
            <Loader
              style={loaderStyle}
              type="Circles"
              color="#fff"
              height={80}
              width={80}
            />
          ) : null}
          <Routes>
            <Route
              path="/"
              element={<LoginComponent loginStatus={checkLoginStatus} />}
            />
            <Route
              path="*"
              element={<LoginComponent loginStatus={checkLoginStatus} />}
            />
            <Route
              path="/login"
              element={<LoginComponent loginStatus={checkLoginStatus} />}
            />
            {/* <Route path="*" element={<LoginComponent/>}/> */}
          </Routes>
        </Router>
      </Suspense>
    );
  } else {
    // window.history.pushState({},'Dashboard',"/kts/dashboard")
    return (
      <Suspense
        fallback={
          <Loader
            style={loaderStyle}
            type="Circles"
            color="#fff"
            height={80}
            width={80}
          />
        }
      >
        <ToastContainer />
        <Router>
          <Navigation loginStatus={checkLoginStatus} />
          <Wrapper>
            {loading ? (
              <Loader
                style={loaderStyle}
                type="Circles"
                color="#fff"
                height={80}
                width={80}
              />
            ) : null}
            <Routes>
              {AppRoutes.map((el, key) => {
                return !el.hasChild ? (
                  el.accessibility ? (
                    <Route
                      key={key}
                      path={el.path}
                      element={<el.component myprops={el.props} />}
                    />
                  ) : null
                ) : (
                  el.children.map((ch, ky) => {
                    return ch.accessibility ? (
                      <Route
                        key={ky}
                        path={ch.path}
                        element={<ch.component myprops={ch.props} />}
                      />
                    ) : null;
                  })
                );
              })}
              <Route
                path="/login"
                element={<LoginComponent loginStatus={checkLoginStatus} />}
              />
              <Route path="/add-contact/:id" element={<AddContact />} />
              <Route path="/add-place/:id" element={<AddPlace />} />
              <Route path="/new-user/:id" element={<AddUser />} />
              {/* <Route path="/assign-depto2larea/:id" element={<AssignDeptLocalArea />} /> */}
              <Route
                path="/update-dept/:id"
                element={<AssignDeptLocalArea />}
              />
              <Route
                path="/assign-deptl2larea"
                element={<AssignDeptLocalArea />}
              />
              <Route path="/" element={<Dashboard />} />
              <Route path="*" element={<Dashboard />} />
            </Routes>
          </Wrapper>
        </Router>
      </Suspense>
    );
  }
}

export default App;
