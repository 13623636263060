import React, { useState } from "react";
import { getUserData } from "../../../services/AuthService";
import { updatePwd } from "../../../services/HTTPService";
import { toast } from "react-toastify";
import swal from "sweetalert";
import { logout } from "../../../services/AuthService";
import { useNavigate } from "react-router-dom";

export default function DialogUpdatePassword(props) {
  const navigate = useNavigate();
  const defaultPayload = {
    id: getUserData().id,
    uname: getUserData().username,
    password: "",
    newpwd: "",
    confirmpwd: "",
  };
  const [payload, setPayload] = useState(defaultPayload);
  const toggleVisible = props.toggleVisible;
  //
  const updateFieldsData = (e) => {
    const { name, value } = e.target;
    setPayload({ ...payload, [name]: value });
  };
  //
  const checkValidation = () => {
    if (payload.password === "" || payload.password.length < 8) {
      toast("Pl Check Current Password.");
    } else if (payload.password === payload.newpwd) {
      toast("Old and New Password can not be same.");
    } else if (payload.newpwd === "" || payload.newpwd.length < 8) {
      toast("Pl Check New Password.");
    } else if (
      payload.confirmpwd === "" ||
      payload.newpwd !== payload.confirmpwd
    ) {
      toast("Confirm Password do not matched with New Entered Password.");
    } else {
      updatePwd(payload)
        .then((response) => {
          if (response.data.status === "success") {
            // console.log(response.data);
            toast.success(response.data.msg);
            resetForm();
            toggleVisible();
          } else if (response.data.status === "warning") {
            swal("Invalid Details !", response.data.msg, "warning");
            // console.log(response.data);
          } else if (response.data.status === "error") {
            swal("Error.?", response.data.msg, "error");
            // console.log(response.data);
          } else if (response.data.status === "logout") {
            logout();
            navigate("/login");
          } else {
            toast.error(
              "Error in Updating, Something went happned. " +
                response.data.status
            );
          }
        })
        .catch((res) => {
          navigate("/dashboard");
          toast.error("Catch Error, Something went happned");
        });
    }
  };
  const resetForm = () => {
    setPayload({ ...defaultPayload });
  };
  const cancelHandler = () => {
    toggleVisible();
    resetForm();
  };
  //
  const view = () => {
    return (
      <>
        <div className="dialog-container">
          <div className="">
            <h4 style={{ textAlign: "center" }}>Update Password</h4>
            <div className="mt-4 mb-2">
              {/* <label className="form-label" htmlFor="">
                Current Password <span style={{ color: "red" }}>*</span>
              </label> */}
              <input
                type="text"
                name="password"
                value={payload.password}
                className="form-control"
                placeholder="Current Password"
                onChange={updateFieldsData}
              ></input>
            </div>
            <div className="mt-2 mb-2">
              {/* <label className="form-label" htmlFor="">
                New Password <span style={{ color: "red" }}>*</span>
              </label> */}
              <input
                type="text"
                name="newpwd"
                value={payload.newpwd}
                className="form-control"
                placeholder="New Password"
                onChange={updateFieldsData}
              ></input>
            </div>
            <div className="mt-2 mb-4">
              {/* <label className="form-label" htmlFor="">
                Confirm Password <span style={{ color: "red" }}>*</span>
              </label> */}
              <input
                type="text"
                name="confirmpwd"
                value={payload.confirmpwd}
                className="form-control"
                placeholder="Confirm Password"
                onChange={updateFieldsData}
              ></input>
            </div>
          </div>
          <div className="button-container">
            <button
              className="btn app-btn-secondary"
              onClick={() => cancelHandler()}
            >
              Cancel
            </button>
            <button className="btn app-btn-primary" onClick={checkValidation}>
              Update
            </button>
          </div>
        </div>
        <div className="confirm-bg"></div>
      </>
    );
  };

  return <div>{props.isVisible && view()}</div>;
}
