import React, { useState } from "react";
import { getDirectoryDeptData } from "../../services/HTTPService";
import Datatable from "../../components/Shared/Datatable/Datatable";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import DialogDeleteData from "../../components/Shared/DialogsBoxes/DialogDeleteData";
import { useNavigate } from "react-router-dom";

const ViewDirWithDept = (props) => {
  const [directoryDeptData, setDirectoryDeptData] = useState("");
  const [totalRows, setTotalRows] = useState(0);
  const navigate = useNavigate();
  const columns = [
    {
      name: "id",
      title: "SNo",
      sorting: true,
    },
    {
      name: "name",
      title: "Name",
      sorting: true,
    },
    {
      name: "contact1",
      title: "Contact01",
      sorting: false,
    },
    {
      name: "designation",
      title: "Designation",
      sorting: true,
    },
    {
      name: "department",
      title: "Department",
      sorting: true,
    },
    {
      name: "fromarea",
      title: "FromArea",
      sorting: false,
    },
    {
      name: "sewaarea",
      title: "SewaArea",
      sorting: false,
    },

    {
      name: "prioritysewadar",
      title: "PBit",
      sorting: true,
    },
    {
      name: "action",
      title: "Action",
      sorting: false,
    },
  ];
  const options = {
    theme: "#15a362",
    rows: [5, 10, 20, 50, 100],
    pagination: true,
    globalFilter: true,
  };
  const cusFilter = {
    page: 1,
    globalFilter: "",
    rows: 10,
    sortOrder: 1,
    sortField: "id",
    // areaid:3,
  };
  const [filter, setFilter] = useState(cusFilter);
  const editDeptDirectory = (id) => {
    navigate(`/update-dept/${id}`);
  };
  //
  const onLoad = (filterVal) => {
    getDirectoryDeptData(filterVal).then((res) => {
      let dta = res.data.data;
      dta.map((el, key) => {
        let action = (
          <div style={{ width: "180px" }}>
            {/* Edit Handler Start */}
            <OverlayTrigger
              placement="top"
              delay={{ show: 200, hide: 200 }}
              overlay={
                <Tooltip id="button-tooltip" {...props}>
                  Edit
                </Tooltip>
              }
            >
              <span
                className="pt-0 pb-0"
                style={{
                  marginRight: "10px",
                  color: "#333",
                  cursor: "pointer",
                  fontSize: 15,
                }}
                onClick={() => editDeptDirectory(el.id)}
              >
                <span className="fas fa-pen-square"></span>
              </span>
            </OverlayTrigger>
            {/* Edit Handler End */}

            {/* Delete Handler Start */}
            <OverlayTrigger
              placement="top"
              delay={{ show: 200, hide: 200 }}
              overlay={
                <Tooltip id="button-tooltip" {...props}>
                  Delete
                </Tooltip>
              }
            >
              <span
                style={{
                  color: "#333",
                  fontSize: 15,
                }}
              >
                <DialogDeleteData
                  id={el.id}
                  filter={filter}
                  lazyLoad={onLoad}
                  whatsDel={"directorydept"}
                />
              </span>
            </OverlayTrigger>
            {/* Delete Handler End */}
          </div>
        );
        dta[key].action = action;
        return 0;
      });
      setDirectoryDeptData(dta);
      setTotalRows(res.data.total);
      setFilter(filterVal);
    });
  };
  //
  return (
    <div className="app-content pt-3 p-md-3 p-lg-4">
      <div className="container-xl">
        <h1 className="app-page-title">VIEW DEPARTMENT DIRECTORY</h1>
        <div className="row" style={{ height: "10px" }}></div>
        <div className="row">
          <div className="col-12 app-card-settings app-card pt-1 pb-3">
            <Datatable
              options={options}
              columns={columns}
              filter={filter}
              data={directoryDeptData}
              totalRows={totalRows}
              lazyLoad={onLoad}
            ></Datatable>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewDirWithDept;
