import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import swal from "sweetalert";
import { logout, getAreaName, getRole, getName } from "../services/AuthService";
import { getDashboardData } from "../services/HTTPService";
//
const Dashboard = () => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  //
  let areawiseMale = 0;
  let areawiseFemale = 0;
  let areawiseTotal = 0;
  //
  let centrewiseMale = 0;
  let centrewiseFemale = 0;
  let centrewiseTotal = 0;
  //
  let placewiseMale = 0;
  let placewiseFemale = 0;
  let placewiseTotal = 0;
  //
  let desigwiseMale = 0;
  let desigwiseFemale = 0;
  let desigwiseTotal = 0;
  //
  let deptwiseMale = 0;
  let deptwiseFemale = 0;
  let deptwiseTotal = 0;
  //
  const [dashboardData, setDashboardData] = useState({
    cards: [],
    areawisestatus: [],
    centrewisestatus: [],
    placewisestatus: [],
    desigwisestatus: [],
    deptwisestatus: [],
  });
  const getDashboard = () => {
    if (loading === true) {
      setLoading(false);
      getDashboardData()
        .then((response) => {
          if (response.data.status === "success") {
            // console.log(response.data);
            setDashboardData({ ...response.data });
            // toast.success(response.data.msg);
          } else if (response.data.status === "error") {
            console.log(response.data);
            swal("Error.?", response.data.msg, "error");
          } else if (response.data.status === "logout") {
            logout();
            navigate("/login");
          } else {
            toast.error(
              "Data Not Found, Something went happned. " + response.data.status
            );
          }
        })
        .catch((res) => {
          navigate("/dashboard");
          toast.error("API Error, Something went happned");
        });
    }
  };
  useEffect(() => {
    getDashboard();
  });

  return (
    <div className="app-content pt-3 p-md-3 p-lg-4">
      <div className="container-xl">
        <h1 className="app-page-title">
          {getAreaName().toUpperCase()}: {getName().toUpperCase()} DASHBOARD{" "}
        </h1>
        <div className="row g-4 mb-4">
          <h5>Quick Links</h5>
          {getRole() <= 3 ? (
            <div className="col-6 col-md-3">
              <NavLink to="/add-contact">
                <div
                  className="app-card app-card-stat shadow-sm h-100"
                  style={{ background: "#15A362", color: "white" }}
                >
                  <div className="app-card-body p-3 p-lg-4">
                    <h4 className="stats-type mb-1" style={{ color: "white" }}>
                      Register
                      <br />
                      New Contact
                    </h4>
                  </div>
                  <span
                    style={{ cursor: "pointer" }}
                    className="app-card-link-mask"
                  />
                </div>
              </NavLink>
            </div>
          ) : null}
          {getRole() <= 5 ? (
            <>
              <div className="col-6 col-md-3">
                <NavLink to="/print-directory">
                  <div
                    className="app-card app-card-stat shadow-sm h-100"
                    style={{ background: "#15A362", color: "white" }}
                  >
                    <div className="app-card-body p-3 p-lg-4">
                      <h4
                        className="stats-type mb-1"
                        style={{ color: "white" }}
                      >
                        Print
                        <br />
                        Directory
                      </h4>
                    </div>
                    <span
                      style={{ cursor: "pointer" }}
                      className="app-card-link-mask"
                    />
                  </div>
                </NavLink>
              </div>
              <div className="col-6 col-md-3">
                <NavLink to="/print-dir-Dept">
                  <div
                    className="app-card app-card-stat shadow-sm h-100"
                    style={{ background: "#15A362", color: "white" }}
                  >
                    <div className="app-card-body p-3 p-lg-4">
                      <h4
                        className="stats-type mb-1"
                        style={{ color: "white" }}
                      >
                        Print
                        <br />
                        Department Wise
                      </h4>
                    </div>
                    <span
                      style={{ cursor: "pointer" }}
                      className="app-card-link-mask"
                    />
                  </div>
                </NavLink>
              </div>
              <div className="col-6 col-md-3">
                <NavLink to="/print-list">
                  <div
                    className="app-card app-card-stat shadow-sm h-100"
                    style={{ background: "#15A362", color: "white" }}
                  >
                    <div className="app-card-body p-3 p-lg-4">
                      <h4
                        className="stats-type mb-1"
                        style={{ color: "white" }}
                      >
                        Print
                        <br />
                        Lists
                      </h4>
                    </div>
                    <span
                      style={{ cursor: "pointer" }}
                      className="app-card-link-mask"
                    />
                  </div>
                </NavLink>
              </div>
            </>
          ) : null}
        </div>
        <div className="row g-4 mb-4">
          <div className="col-6 col-md-3">
            <NavLink to="/view-directory">
              <div
                className="app-card app-card-stat shadow-sm h-100"
                style={{ background: "#cc0066", color: "white" }}
              >
                <div className="app-card-body p-3 p-lg-4">
                  <h4 className="stats-type mb-1" style={{ color: "white" }}>
                    View
                    <br />
                    Directory
                  </h4>
                </div>
                <span
                  style={{ cursor: "pointer" }}
                  className="app-card-link-mask"
                />
              </div>
            </NavLink>
          </div>
          <div className="col-6 col-md-3">
            <NavLink to="/approved-devices">
              <div
                className="app-card app-card-stat shadow-sm h-100"
                style={{ background: "#8600b3", color: "white" }}
              >
                <div className="app-card-body p-3 p-lg-4">
                  <h4 className="stats-type mb-1" style={{ color: "white" }}>
                    Show
                    <br />
                    Approved Devices
                  </h4>
                </div>
                <span
                  style={{ cursor: "pointer" }}
                  className="app-card-link-mask"
                />
              </div>
            </NavLink>
          </div>
          <div className="col-6 col-md-3">
            <NavLink to="/pending-devices">
              <div
                className="app-card app-card-stat shadow-sm h-100"
                style={{ background: "#ff1a75", color: "white" }}
              >
                <div className="app-card-body p-3 p-lg-4">
                  <h4 className="stats-type mb-1" style={{ color: "white" }}>
                    Show
                    <br />
                    Pending Devices
                  </h4>
                </div>
                <span
                  style={{ cursor: "pointer" }}
                  className="app-card-link-mask"
                />
              </div>
            </NavLink>
          </div>
        </div>
        <hr />
        <div className="row g-4 mb-4">
          <h5>Overview (AREA: {getAreaName()})</h5>
          <div className="col-12 col-md-4">
            <NavLink to="/approved-devices">
              <div
                className="app-card app-card-stat shadow-sm h-100"
                style={{ background: "#333", color: "white" }}
              >
                <div className="app-card-body p-3 p-lg-4">
                  <div className="row">
                    <div className="col-6">
                      <h4
                        className="stats-type mb-1"
                        style={{ color: "white", fontSize: "18px" }}
                      >
                        Approved{": "}
                        {dashboardData.cards.totalDevices}
                      </h4>
                    </div>
                    <div className="col-6">
                      <h4
                        className="stats-type mb-1"
                        style={{ color: "white", fontSize: "18px" }}
                      >
                        Pending{": "}
                        {dashboardData.cards.totalPendingDevices}
                      </h4>
                    </div>
                  </div>
                  <div></div>
                </div>
                <div className="row">
                  <div className="col-4">
                    <div className="numbers">
                      <p className="">Active</p>
                      <h5 className="card-title" style={{ color: "#20c997" }}>
                        {dashboardData.cards.totalActive}
                      </h5>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="numbers">
                      <p className="">Non-Active</p>
                      <h5 className="card-title" style={{ color: "#ffc107" }}>
                        {dashboardData.cards.totalNonActive}
                      </h5>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="numbers">
                      <p className="">Pending</p>
                      <h5 className="card-title" style={{ color: "#0dcaf0" }}>
                        {dashboardData.cards.totalPendingDevices}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </NavLink>
          </div>
          <div className="col-12 col-md-4">
            <NavLink to="/">
              <div
                className="app-card app-card-stat shadow-sm h-100"
                style={{ background: "#333", color: "white" }}
              >
                <div className="app-card-body p-3 p-lg-4">
                  <h4
                    className="stats-type mb-1"
                    style={{ color: "white", fontSize: "18px" }}
                  >
                    Total Areas {": "}
                    {dashboardData.cards.totalArea}
                  </h4>
                </div>
                <div className="row">
                  <div className="col-3">
                    <div className="numbers">
                      <p className="">C</p>
                      <h5 className="card-title" style={{ color: "#20c997" }}>
                        {dashboardData.cards.totalC}
                      </h5>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="numbers">
                      <p className="">SC</p>
                      <h5 className="card-title" style={{ color: "#ffc107" }}>
                        {dashboardData.cards.totalSC}
                      </h5>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="numbers">
                      <p className="">SP</p>
                      <h5 className="card-title" style={{ color: "#0dcaf0" }}>
                        {dashboardData.cards.totalSP}
                      </h5>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="numbers">
                      <p className="">TOTAL</p>
                      <h5 className="card-title" style={{ color: "#fd7e14" }}>
                        {dashboardData.cards.totalPlace}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </NavLink>
          </div>
          <div className="col-12 col-md-4">
            <NavLink to="/">
              <div
                className="app-card app-card-stat shadow-sm h-100"
                style={{ background: "#333", color: "white" }}
              >
                <div className="app-card-body p-3 p-lg-4">
                  <h4
                    className="stats-type mb-1"
                    style={{ color: "white", fontSize: "18px" }}
                  >
                    Total Sewadars {": "}
                    {dashboardData.cards.totalDirUser}
                  </h4>
                </div>
                <div className="row">
                  <div className="col-3">
                    <div className="numbers">
                      <p className="">M</p>
                      <h5 className="card-title" style={{ color: "#20c997" }}>
                        {dashboardData.cards.totalMaleUSer}
                      </h5>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="numbers">
                      <p className="">F</p>
                      <h5 className="card-title" style={{ color: "#ffc107" }}>
                        {dashboardData.cards.totalFemaleUser}
                      </h5>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="numbers">
                      <p className="">Active</p>
                      <h5 className="card-title" style={{ color: "#0dcaf0" }}>
                        {dashboardData.cards.totalActDirUser}
                      </h5>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="numbers">
                      <p className="">NonActive</p>
                      <h5 className="card-title" style={{ color: "#fd7e14" }}>
                        {dashboardData.cards.totalNonActDirUser}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </NavLink>
          </div>
        </div>
        <div className="row g-4 mb-4">
          <h5>STATUS</h5>
          <div className="col-12 col-md-4">
            <NavLink to="/">
              <div
                className="app-card app-card-stat shadow-sm h-100"
                style={{ background: "#fff" }}
              >
                <div className="app-card-body pt-2">
                  <h4
                    className="stats-type"
                    style={{
                      color: "black",
                      fontSize: "18px",
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  >
                    Area Wise Directory (Sewadars)
                  </h4>
                  <span>Area (Male-Female) wise Status.</span>
                </div>
                <hr />
                <table className="table">
                  <thead>
                    <tr>
                      <th>SN</th>
                      <th>AREA</th>
                      <th>M</th>
                      <th>F</th>
                      <th>T</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dashboardData.areawisestatus.map((val, key) => {
                      areawiseMale += Number(val.male);
                      areawiseFemale += Number(val.female);
                      areawiseTotal += Number(val.male) + Number(val.female);
                      return (
                        <>
                          <tr key={key}>
                            <td className="text-center">{key + 1}</td>
                            <td className="text-left" style={{ margin: "5px" }}>
                              {val.area}
                            </td>
                            <td className="text-center">{val.male}</td>
                            <td className="text-center">{val.female}</td>
                            <td className="text-center">
                              {Number(val.male) + Number(val.female)}
                            </td>
                          </tr>
                        </>
                      );
                    })}
                    <tr>
                      <th colSpan={2}>Total</th>
                      <th>{Number(areawiseMale)}</th>
                      <th>{Number(areawiseFemale)}</th>
                      <th>{Number(areawiseTotal)}</th>
                    </tr>
                  </tbody>
                </table>
              </div>
            </NavLink>
          </div>
          <div className="col-12 col-md-4">
            <NavLink to="/">
              <div
                className="app-card app-card-stat shadow-sm h-100"
                style={{ background: "#fff" }}
              >
                <div className="app-card-body pt-2">
                  <h4
                    className="stats-type"
                    style={{
                      color: "black",
                      fontSize: "18px",
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  >
                    Centre Wise Directory (Sewadars)
                  </h4>
                  <span>
                    {" "}
                    {getAreaName()} Area (Centers & gender) wise Status.
                  </span>
                </div>
                <hr />
                <table className="table">
                  <thead>
                    <tr>
                      <th>SN</th>
                      <th>CENTER</th>
                      <th>M</th>
                      <th>F</th>
                      <th>T</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dashboardData.centrewisestatus.map((val, key) => {
                      centrewiseMale += Number(val.male);
                      centrewiseFemale += Number(val.female);
                      centrewiseTotal += Number(val.male) + Number(val.female);
                      return (
                        <>
                          <tr key={key}>
                            <td className="text-center">{key + 1}</td>
                            <td className="text-left" style={{ margin: "5px" }}>
                              {val.centre}
                            </td>
                            <td className="text-center">{val.male}</td>
                            <td className="text-center">{val.female}</td>
                            <td className="text-center">
                              {Number(val.male) + Number(val.female)}
                            </td>
                          </tr>
                        </>
                      );
                    })}
                    <tr>
                      <th colSpan={2}>Total</th>
                      <th>{Number(centrewiseMale)}</th>
                      <th>{Number(centrewiseFemale)}</th>
                      <th>{Number(centrewiseTotal)}</th>
                    </tr>
                  </tbody>
                </table>
              </div>
            </NavLink>
          </div>
        </div>
        <div className="row g-4 mb-4">
          <div className="col-12 col-md-4">
            <NavLink to="/">
              <div
                className="app-card app-card-stat shadow-sm h-100"
                style={{ background: "#fff" }}
              >
                <div className="app-card-body pt-2">
                  <h4
                    className="stats-type"
                    style={{
                      color: "black",
                      fontSize: "18px",
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  >
                    Place(C/SC/SP) Wise Directory (Sewadars)
                  </h4>
                  <span>
                    {getAreaName()} area (Places & Gender) wise Status.
                  </span>
                </div>
                <hr />
                <table className="table">
                  <thead>
                    <tr>
                      <th>SN</th>
                      <th>PLACE</th>
                      <th>M</th>
                      <th>F</th>
                      <th>T</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dashboardData.placewisestatus.map((val, key) => {
                      placewiseMale += Number(val.male);
                      placewiseFemale += Number(val.female);
                      placewiseTotal += Number(val.male) + Number(val.female);
                      return (
                        <>
                          <tr key={key}>
                            <td className="text-center">{key + 1}</td>
                            <td className="text-left" style={{ margin: "5px" }}>
                              {val.place}
                            </td>
                            <td className="text-center">{Number(val.male)}</td>
                            <td className="text-center">
                              {Number(val.female)}
                            </td>
                            <td className="text-center">
                              {Number(val.male) + Number(val.female)}
                            </td>
                          </tr>
                        </>
                      );
                    })}
                    <tr>
                      <th colSpan={2}>Total</th>
                      <th>{Number(placewiseMale)}</th>
                      <th>{Number(placewiseFemale)}</th>
                      <th>{Number(placewiseTotal)}</th>
                    </tr>
                  </tbody>
                </table>
              </div>
            </NavLink>
          </div>
          <div className="col-12 col-md-4">
            <NavLink to="/">
              <div
                className="app-card app-card-stat shadow-sm h-100"
                style={{ background: "#fff" }}
              >
                <div className="app-card-body pt-2">
                  <h4
                    className="stats-type"
                    style={{
                      color: "black",
                      fontSize: "18px",
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  >
                    Designations Wise Directory (Sewadars)
                  </h4>
                  <span>
                    {" "}
                    {getAreaName()} Area (Designation & Gender) wise Status.
                  </span>
                </div>
                <hr />
                <table className="table">
                  <thead>
                    <tr>
                      <th>SN</th>
                      <th>DESIGNATION</th>
                      <th>M</th>
                      <th>F</th>
                      <th>T</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dashboardData.desigwisestatus.map((val, key) => {
                      desigwiseMale += Number(val.male);
                      desigwiseFemale += Number(val.female);
                      desigwiseTotal += Number(val.male) + Number(val.female);
                      return (
                        <>
                          <tr key={key}>
                            <td className="text-center">{key + 1}</td>
                            <td className="text-left" style={{ margin: "5px" }}>
                              {val.designation}
                            </td>
                            <td className="text-center">{Number(val.male)}</td>
                            <td className="text-center">
                              {Number(val.female)}
                            </td>
                            <td className="text-center">
                              {Number(val.male) + Number(val.female)}
                            </td>
                          </tr>
                        </>
                      );
                    })}
                    <tr>
                      <th colSpan={2}>Total</th>
                      <th>{Number(desigwiseMale)}</th>
                      <th>{Number(desigwiseFemale)}</th>
                      <th>{Number(desigwiseTotal)}</th>
                    </tr>
                  </tbody>
                </table>
              </div>
            </NavLink>
          </div>
          <div className="col-12 col-md-4">
            <NavLink to="/">
              <div
                className="app-card app-card-stat shadow-sm h-100"
                style={{ background: "#fff" }}
              >
                <div className="app-card-body pt-2">
                  <h4
                    className="stats-type"
                    style={{
                      color: "black",
                      fontSize: "18px",
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  >
                    Department Wise Directory (Sewadars)
                  </h4>
                  <span>
                    {" "}
                    {getAreaName()} Area (Department & Gender) wise Status.
                  </span>
                </div>
                <hr />
                <table className="table">
                  <thead>
                    <tr>
                      <th>SN</th>
                      <th>DEPARTMENT</th>
                      <th>M</th>
                      <th>F</th>
                      <th>T</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dashboardData.deptwisestatus.map((val, key) => {
                      deptwiseMale += Number(val.male);
                      deptwiseFemale += Number(val.female);
                      deptwiseTotal += Number(val.male) + Number(val.female);
                      return (
                        <>
                          <tr key={key}>
                            <td className="text-center">{key + 1}</td>
                            <td className="text-left" style={{ margin: "5px" }}>
                              {val.department}
                            </td>
                            <td className="text-center">{Number(val.male)}</td>
                            <td className="text-center">
                              {Number(val.female)}
                            </td>
                            <td className="text-center">
                              {Number(val.male) + Number(val.female)}
                            </td>
                          </tr>
                        </>
                      );
                    })}
                    <tr>
                      <th colSpan={2}>Total</th>
                      <th>{Number(deptwiseMale)}</th>
                      <th>{Number(deptwiseFemale)}</th>
                      <th>{Number(deptwiseTotal)}</th>
                    </tr>
                  </tbody>
                </table>
              </div>
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
