// import React, { useState, useEffect } from "react";
// import { toast } from "react-toastify";
// import { getRolesAccess } from "../services/HTTPService";
// import { useNavigate } from "react-router-dom";
//
import { lazy } from "react";
import ViewDirWithDept from "../pages/phonedirectory/ViewDirWithDept";
import ViewDepartments from "../pages/settings/ViewDepartments";
import ViewDesignation from "../pages/settings/ViewDesignation";
import ViewDeviceApproved from "../pages/settings/ViewDeviceApproved";
import ViewDevicePending from "../pages/settings/ViewDevicePending";
import ViewUserRoles from "../pages/settings/ViewUserRoles";
import ViewUsers from "../pages/settings/ViewUsers";
import Dashboard from "../pages/Dashboard";
import ViewDirectory from "../pages/phonedirectory/ViewDirectory";
import AssignDeptLocalArea from "../pages/phonedirectory/AssignDeptLocalArea";
import AssignDeptL2Other from "../pages/phonedirectory/AssignDeptL2Other";
import AssignDeptO2Local from "../pages/phonedirectory/AssignDeptO2Local";
import PrintDirectory from "../pages/reports/printDirectory/PrintDirectory";
import PrintDirDepWise from "../pages/reports/printDirectory/PrintDirDepWise";
import PrintApprovedDevices from "../pages/reports/printDirectory/PrintApprovedDevices";
//
const AddContact = lazy(() => import("../pages/phonedirectory/AddContact"));
const AddUser = lazy(() => import("../pages/settings/AddUser"));
const ViewAreas = lazy(() => import("../pages/satsangplaces/ViewAreas"));
const ViewPlaces = lazy(() => import("../pages/satsangplaces/ViewPlaces"));
const ViewCentres = lazy(() => import("../pages/satsangplaces/ViewCentres"));
const AddPlace = lazy(() => import("../pages/satsangplaces/AddPlace"));
const PrintLists = lazy(() => import("../pages/reports/printlists/PrintLists"));
//

// export function AppRoute() {
//   const navigate = useNavigate();
//   const [sidebarMenu, setSidebarMenu] = useState([]);
//   const getRoles = () => {
//     getRolesAccess().then((response) => {
//       if (response.status === 200) {
//         // console.log(response.data);
//         setSidebarMenu(response.data);
//       } else {
//         navigate(`/dashboard`);
//         console.log(response.data);
//         toast.error(response.data.msg);
//       }
//     });
//   };
//   useEffect(() => {
//     getRoles();
//   }, []);
//   //
//   console.log("DB Access", sidebarMenu);
// }
// //

const AppRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "fa fa-tachometer-alt",
    hasChild: false,
    component: Dashboard,
    showOnSidebar: true,
    accessibility: false,
    children: [],
  },
  {
    name: "Phone Directory",
    icon: "fa fa-user-tie",
    hasChild: true,
    component: "",
    showOnSidebar: true,
    children: [
      {
        path: "/add-contact",
        name: "Add Contact",
        // icon: "fa fa-dashboard",
        // hasChild: false,
        component: AddContact,
        showOnSidebar: true,
        accessibility: true,
        // children: [],
      },
      {
        path: "/assign-deptl2larea",
        name: "AssignDept L2L Area",
        icon: "fa fa-dashboard",
        hasChild: false,
        component: AssignDeptLocalArea,
        showOnSidebar: true,
        accessibility: true,
        children: [],
      },
      {
        path: "/assign-deptl2oarea",
        name: "AssignDept L2O Area",
        icon: "fa fa-dashboard",
        hasChild: false,
        component: AssignDeptL2Other,
        showOnSidebar: true,
        accessibility: true,
        children: [],
      },
      {
        path: "/assign-depto2larea",
        name: "AssignDept O2L Area",
        icon: "fa fa-dashboard",
        hasChild: false,
        component: AssignDeptO2Local,
        showOnSidebar: true,
        accessibility: true,
        children: [],
      },
      {
        path: "/view-directory",
        name: "View Directory",
        icon: "fa fa-dashboard",
        hasChild: false,
        component: ViewDirectory,
        showOnSidebar: true,
        accessibility: true,
        props: { showAddBtn: true, nextvar: "nextval" },
        children: [],
      },
      {
        path: "/view-dirwithdept",
        name: "View Dept Directory",
        icon: "fa fa-dashboard",
        hasChild: false,
        component: ViewDirWithDept,
        showOnSidebar: false,
        accessibility: true,
        props: { showAddBtn: true, nextvar: "nextval" },
        children: [],
      },
    ],
  },
  {
    name: "Satsang Place",
    icon: "fa fa-user-tie",
    hasChild: true,
    component: "",
    showOnSidebar: true,
    accessibility: true,
    children: [
      {
        path: "/view-area",
        name: "View Areas",
        icon: "fa fa-dashboard",
        hasChild: false,
        component: ViewAreas,
        showOnSidebar: true,
        accessibility: true,
        children: [],
      },
      {
        path: "/view-centre",
        name: "View Centres",
        icon: "fa fa-dashboard",
        hasChild: false,
        component: ViewCentres,
        showOnSidebar: true,
        accessibility: true,
        props: { showAddBtn: true, nextvar: "nextval" },
        children: [],
      },
      {
        path: "/add-place",
        name: "Add Place",
        icon: "fa fa-dashboard",
        hasChild: false,
        component: AddPlace,
        showOnSidebar: false,
        accessibility: true,
        children: [],
      },
      {
        path: "/view-place",
        name: "View Places",
        icon: "fa fa-dashboard",
        hasChild: false,
        component: ViewPlaces,
        showOnSidebar: true,
        accessibility: true,
        props: { showAddBtn: true, nextvar: "nextval" },
        children: [],
      },
    ],
  },
  {
    name: "Print",
    icon: "fa fa-user-tie",
    hasChild: true,
    component: "",
    showOnSidebar: true,
    accessibility: true,
    children: [
      {
        path: "/print-directory",
        name: "Print Directory",
        icon: "fa fa-dashboard",
        hasChild: false,
        component: PrintDirectory,
        showOnSidebar: true,
        accessibility: true,
        children: [],
      },
      {
        path: "/print-dir-Dept",
        name: "Print Dept Dir",
        icon: "fa fa-dashboard",
        hasChild: false,
        component: PrintDirDepWise,
        showOnSidebar: true,
        accessibility: true,
        children: [],
      },
      {
        path: "/print-list",
        name: "Print Lists",
        icon: "fa fa-dashboard",
        hasChild: false,
        component: PrintLists,
        showOnSidebar: true,
        accessibility: true,
        children: [],
      },
      {
        path: "/print-approved-devices",
        name: "Print Approved Devices",
        icon: "fa fa-dashboard",
        hasChild: false,
        component: PrintApprovedDevices,
        showOnSidebar: true,
        accessibility: true,
        children: [],
      },
    ],
  },
  {
    name: "Settings",
    icon: "fa fa-user-tie",
    hasChild: true,
    component: "",
    showOnSidebar: true,
    accessibility: true,
    children: [
      {
        path: "/approved-devices",
        name: "Approced Devices",
        icon: "fa fa-dashboard",
        hasChild: false,
        component: ViewDeviceApproved,
        showOnSidebar: true,
        accessibility: true,
        children: [],
      },
      {
        path: "/pending-devices",
        name: "Pending Devices",
        icon: "fa fa-dashboard",
        hasChild: false,
        component: ViewDevicePending,
        showOnSidebar: true,
        accessibility: true,
        children: [],
      },
      {
        path: "/view-departments",
        name: "View Departments",
        icon: "fa fa-dashboard",
        hasChild: false,
        component: ViewDepartments,
        showOnSidebar: true,
        accessibility: true,
        children: [],
      },
      {
        path: "/view-designation",
        name: "View Designation",
        icon: "fa fa-dashboard",
        hasChild: false,
        component: ViewDesignation,
        showOnSidebar: true,
        accessibility: true,
        children: [],
      },
      {
        path: "/view-roles",
        name: "View User Roles",
        icon: "fa fa-dashboard",
        hasChild: false,
        component: ViewUserRoles,
        showOnSidebar: true,
        accessibility: true,
        children: [],
      },
      {
        path: "/new-user",
        name: "New Users",
        icon: "fa fa-dashboard",
        hasChild: false,
        component: AddUser,
        showOnSidebar: false,
        accessibility: true,
        children: [],
      },
      {
        path: "/view-users",
        name: "View Users",
        icon: "fa fa-dashboard",
        hasChild: false,
        component: ViewUsers,
        showOnSidebar: true,
        accessibility: true,
        props: { showAddBtn: true, nextvar: "nextval" },
        children: [],
      },
    ],
  },
];

// console.log("AppRoutes", AppRoutes);
export default AppRoutes;
