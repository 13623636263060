import React, { useState } from "react";
import { getDirectoryData } from "../../services/HTTPService";
import Datatable from "../../components/Shared/Datatable/Datatable";
import { useNavigate } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import DialogDeleteData from "../../components/Shared/DialogsBoxes/DialogDeleteData";
import { getRole } from "../../services/AuthService";

const ViewDirectory = (props) => {
  const navigate = useNavigate();
  const [directoryData, setDirectoryData] = useState("");
  const [totalRows, setTotalRows] = useState(0);

  const columns = [
    {
      name: "id",
      title: "SNo",
      sorting: true,
    },
    {
      name: "name",
      title: "Name",
      sorting: true,
    },
    {
      name: "contact1",
      title: "Contact01",
      sorting: false,
    },
    {
      name: "contact2",
      title: "Contact02",
      sorting: false,
    },
    {
      name: "gender",
      title: "Gender",
      sorting: true,
    },
    {
      name: "designation",
      title: "Designation",
      sorting: true,
    },
    {
      name: "place",
      title: "Place",
      sorting: true,
    },
    {
      name: "status",
      title: "Staus",
      sorting: true,
    },
    getRole() <= 3 && {
      name: "action",
      title: "Action",
      sorting: false,
    },
  ];
  const options = {
    theme: "#15a362",
    rows: [5, 10, 20, 50, 100],
    pagination: true,
    globalFilter: true,
  };
  const cusFilter = {
    page: 1,
    globalFilter: "",
    rows: 10,
    sortOrder: -1,
    sortField: "id",
    // areaid:3,
  };
  const [filter, setFilter] = useState(cusFilter);
  //
  const editDirectory = (id) => {
    navigate(`/add-contact/${id}`);
  };
  //
  const onLoad = (filterVal) => {
    getDirectoryData(filterVal).then((res) => {
      let dta = res.data.data;
      dta.map((el, key) => {
        let action = (
          <div style={{ width: "180px" }}>
            {/* Edit Handler Start */}
            <OverlayTrigger
              placement="top"
              delay={{ show: 200, hide: 200 }}
              overlay={
                <Tooltip id="button-tooltip" {...props}>
                  Edit
                </Tooltip>
              }
            >
              <span
                className="pt-0 pb-0"
                style={{
                  marginRight: "10px",
                  color: "#1C6DD0",
                  cursor: "pointer",
                }}
                onClick={() => editDirectory(el.id)}
              >
                <span className="fas fa-edit"></span>
              </span>
            </OverlayTrigger>
            {/* Edit Handler End */}
            {/* Delete Handler Start */}
            <OverlayTrigger
              placement="top"
              delay={{ show: 200, hide: 200 }}
              overlay={
                <Tooltip id="button-tooltip" {...props}>
                  Delete
                </Tooltip>
              }
            >
              <span>
                <DialogDeleteData
                  id={el.id}
                  filter={filter}
                  lazyLoad={onLoad}
                  whatsDel={"directory"}
                />
              </span>
            </OverlayTrigger>
            {/* Delete Handler End */}
          </div>
        );
        dta[key].action = action;
        // console.log(dta);
        return 0;
      });
      setDirectoryData(dta);
      setTotalRows(res.data.total);
      setFilter(filterVal);
    });
  };
  return (
    <div className="app-content pt-3 p-md-3 p-lg-4">
      <div className="container-xl">
        <h1 className="app-page-title">View Directory</h1>
        <div className="row">
          {getRole() <= 3 && props.myprops.showAddBtn ? (
            <div className="col-12 app-card-settings app-card pt-3 pb-3">
              <div
                className="col-md-2"
                style={{ float: "left", paddingLeft: 3, paddingRight: 3 }}
              >
                <button
                  className="btn app-btn-primary"
                  style={{ width: "100%" }}
                  onClick={() => navigate("/add-contact")}
                >
                  <span className="fa fa-plus-circle"></span> Add Contact
                </button>
              </div>
            </div>
          ) : null}
        </div>
        <div className="row" style={{ height: "10px" }}></div>
        <div className="row">
          <div className="col-12 app-card-settings app-card pt-1 pb-3">
            <Datatable
              options={options}
              columns={columns}
              filter={filter}
              data={directoryData}
              totalRows={totalRows}
              lazyLoad={onLoad}
            ></Datatable>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewDirectory;
