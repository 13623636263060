import React, { useState } from "react";
import { getUserData } from "../services/AuthService";
import logo from "../assets/images/logo.png";
import Sidebar from "./Sidebar";
import { logout } from "../services/AuthService";
import { useNavigate } from "react-router-dom";
import DialogUpdatePassword from "../components/Shared/DialogsBoxes/DialogUpdatePassword";

export default function Navigation(props) {
  let navigate = useNavigate();
  const [showPwdDialog, setShowPwdDialog] = useState(false);
  const [showSidebar, setShowSidebar] = useState(true);

  const toggleSidebar = () => {
    if (window.innerWidth < 1200) {
      let visibility = showSidebar;
      visibility = !visibility;
      setShowSidebar(visibility);
    }
  };
  const togglePwdDialog = () => {
    setShowPwdDialog(!showPwdDialog);
  };
  const logoutNow = () => {
    props.loginStatus(true);
    navigate("/");
    logout();
    localStorage.clear();
    // window.history.pushState({},'Login',"/kts/login")
  };
  //
  return (
    <header className="app-header fixed-top">
      <DialogUpdatePassword
        isVisible={showPwdDialog}
        toggleVisible={togglePwdDialog}
      />
      <div className="app-header-inner">
        <div className="container-fluid py-2">
          <div className="app-header-content">
            <div className="row justify-content-between align-items-center">
              <div className="col-auto">
                <span
                  style={{ cursor: "pointer", color: "green" }}
                  id="sidepanel-toggler"
                  className="sidepanel-toggler d-inline-block d-xl-none"
                  onClick={toggleSidebar}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={30}
                    height={30}
                    viewBox="0 0 30 30"
                    role="img"
                  >
                    <title>Menu</title>
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeMiterlimit={10}
                      strokeWidth={2}
                      d="M4 7h22M4 15h22M4 23h22"
                    />
                  </svg>
                </span>
              </div>
              <div className="search-mobile-trigger d-sm-none col">
                <i className="search-mobile-trigger-icon fas fa-search" />
              </div>
              <div className="app-search-box col"></div>
              <div className="app-utilities col-auto">
                <div
                  className="app-utility-item app-user-dropdown dropdown"
                  style={{ paddingTop: "5px" }}
                >
                  <span
                    className="dropdown-toggle"
                    id="user-dropdown-toggle"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    style={{ cursor: "pointer" }}
                  >
                    {/* <img src="assets/images/user.png" alt="user profile" /> */}
                    {getUserData().name}
                  </span>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="user-dropdown-toggle"
                  >
                    <li>
                      <span
                        className="dropdown-item"
                        onClick={togglePwdDialog}
                        style={{ cursor: "pointer" }}
                      >
                        Change Password
                      </span>
                    </li>
                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    <li>
                      <button
                        className="dropdown-item"
                        href="#"
                        onClick={logoutNow}
                      >
                        Log Out
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="app-sidepanel"
        className={
          showSidebar === true
            ? ["app-sidepanel", "sidepanel-visible"].join(" ")
            : ["app-sidepanel", "sidepanel-hidden"].join(" ")
        }
      >
        <div id="sidepanel-drop" className="sidepanel-drop" />
        <div className="sidepanel-inner d-flex flex-column">
          <span
            style={{ cursor: "pointer" }}
            id="sidepanel-close"
            className="sidepanel-close d-xl-none"
            onClick={toggleSidebar}
          >
            ×
          </span>
          <div className="app-branding">
            <a className="app-logo" href="index.html">
              <img className="logo-icon me-2" src={logo} alt="logo" />
              <span className="logo-text">RSSB Directory</span>
            </a>
          </div>
          <Sidebar toggle={toggleSidebar} />
        </div>
      </div>
    </header>
  );
}
