import React, { useState, useEffect, useRef } from "react";
import { getRole, getAreaId } from "../../services/AuthService";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import swal from "sweetalert";
import {
  getAreaList,
  getRolesList,
  getDesignationList,
  getCentre4mAreaId,
  getPlaces4mCentreId,
  getCentre4mPlaceId,
  addNewUser,
  updateUser,
  getUserDetails,
  getPlaceById,
} from "../../services/HTTPService";
import { logout } from "../../services/AuthService";
import { useNavigate } from "react-router-dom";
import { validations } from "../../services/ValidationService";
import ViewUser from "./ViewUsers";
import { getUserData } from "../../services/AuthService";
//
export default function AddUser() {
  const [userData, updateUserData] = useState(getUserData());
  const updatekey = useParams().id;
  const navigate = useNavigate();
  const myprops = {
    showAddBtn: false,
  };
  const defaultfields = {
    updatekey: updatekey ? updatekey : "",
    uname: "",
    name: "",
    password: "",
    confirmpwd: "",
    role: "",
    roleid: "",
    designation: "",
    designationid: "",
    contact: "",
    area: "",
    areaid: "",
    centre: "",
    centreid: "",
    place: "",
    placeid: "",
    email: "",
    userstatus: "",
    userstatusid: "",
  };
  const [editMode, setEditMode] = useState(false);
  const [fieldsData, setFieldsData] = useState(defaultfields);
  const [roles, setRoles] = useState([]);
  const [designation, setDesignation] = useState([]);
  const [area, setArea] = useState([]);
  const [centre, setCentre] = useState([]);
  const [place, setPlace] = useState([]);
  // const [onUpdatePlaceId, setOnUpdatePlaceId] = useState(); // use when need to load full place list after edit click otherwise no need
  const [showDataTable, setShowDataTable] = useState(true);
  //
  const getArea = () => {
    getAreaList().then((response) => {
      if (response.status === 200) {
        setArea(response.data);
      } else {
        navigate(`/dashboard`);
        toast.error(response.data.msg);
      }
    });
  };
  const getDesignation = () => {
    getDesignationList().then((response) => {
      if (response.status === 200) {
        setDesignation(response.data);
      } else {
        navigate(`/dashboard`);
        console.log(response.data);
        toast.error(response.data.msg);
      }
    });
  };
  const getRoles = () => {
    getRolesList().then((response) => {
      if (response.status === 200) {
        setRoles(response.data);
      } else {
        navigate(`/dashboard`);
        console.log(response.data);
        toast.error(response.data.msg);
      }
    });
  };
  useEffect(() => {
    getArea();
    getDesignation();
    getRoles();
  }, []);
  //
  const onChangeArea = (val) => {
    getCentre4mAreaId({ areaid: val }).then((response) => {
      if (response.status === 200) {
        if (response.data.status === "success") {
          console.log("C", response.data);
          setCentre(response.data.data);
          setFieldsData((prevState) => ({
            ...prevState,
            ["placeid"]: "",
          }));
        } else {
          // console.log(response.data.msg);
          toast.error(response.data.msg);
        }
      } else {
        console.log("error");
        navigate(`/dashboard`);
      }
    });
  };
  const onChangeCentre = (val) => {
    getPlaces4mCentreId({ centreid: val }).then((response) => {
      if (response.status === 200) {
        if (response.data.status === "success") {
          setPlace(response.data.data);
        } else {
          toast.error(response.data.msg);
        }
      } else {
        console.log("error");
        navigate(`/dashboard`);
      }
    });
  };
  const updateFieldsData = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "areaid":
        setFieldsData({ ...fieldsData, [name]: value });
        if (value !== "") {
          onChangeArea(value);
        }
        break;
      case "centreid":
        setFieldsData({ ...fieldsData, [name]: value });
        if (value !== "") {
          onChangeCentre(value);
        }
        break;
      case "contact":
        if (value === "" || validations.numberOnly(value)) {
          setFieldsData({ ...fieldsData, [name]: value });
        }
        break;
      default:
        setFieldsData({ ...fieldsData, [name]: value });
        break;
    }
  };
  const checkValidation = () => {
    // console.log(fieldsData);
    if (fieldsData.uname === "") {
      toast("Username Required.!");
    } else if (fieldsData.name === "") {
      toast("Name Required.!");
    } else if (
      !editMode &&
      (fieldsData.password === "" || fieldsData.password.length < 8)
    ) {
      toast("Pl Check Password, It should not be less than 8 char.!");
    } else if (fieldsData.password !== fieldsData.confirmpwd) {
      toast("Password do not matched with Confirm Password.");
    } else if (fieldsData.roleid === "") {
      toast("Please select Role");
    } else if (fieldsData.designationid === "") {
      toast("Please select Designation");
    } else if (fieldsData.contact === "" || fieldsData.contact.length !== 10) {
      toast("Invalid Contact No.!");
    } else if (fieldsData.placeid === "") {
      toast("Please select Place");
    } else if (fieldsData.userstatusid === "") {
      toast("Please select User Status.!");
    } else {
      if (!editMode) {
        addNewUser(fieldsData)
          .then((response) => {
            if (response.data.status === "success") {
              console.log(response.data);
              toast.success(response.data.msg);
              resetForm();
            } else if (response.data.status === "warning") {
              console.log(response.data);
              swal("Already Registered !", response.data.msg, "warning");
            } else if (response.data.status === "error") {
              console.log(response.data);
              swal("Error.?", response.data.msg, "error");
            } else if (response.data.status === "logout") {
              logout();
              navigate("/login");
            } else {
              toast.error(
                "Error in Registering, Something Went Happned. " +
                  response.data.status
              );
            }
            setShowDataTable(true);
          })
          .catch((res) => {
            navigate("/dashboard");
            toast.error("Chache Error, Something went Wrong");
          });
        setShowDataTable(false);
      } else {
        updateUser(fieldsData)
          .then((response) => {
            if (response.data.status === "success") {
              console.log(response.data);
              toast.success(response.data.msg);
              resetForm();
              navigate("/new-user");
              // window.history.back();
            } else if (response.data.status === "warning") {
              console.log(response.data);
              swal("Already Registered !", response.data.msg, "warning");
            } else if (response.data.status === "error") {
              console.log(response.data);
              swal("Error.?", response.data.msg, "error");
            } else if (response.data.status === "logout") {
              logout();
              navigate("/login");
            } else {
              toast.error(
                "Error in Updating, Something went happned. " +
                  response.data.status
              );
            }
            // setShowDataTable(true);
          })
          .catch((res) => {
            navigate("/dashboard");
            toast.error("Error in Registering, Something went happned");
          });
        // setShowDataTable(false);
      }
    }
  };
  const resetForm = () => {
    setFieldsData({ ...defaultfields });
  };
  //
  const getUpdationDetails = () => {
    getUserDetails({ updatekey: updatekey }).then((response) => {
      if (response.status === 200) {
        if (response.data.status === "success") {
          // console.log("placeid1:", response.data.data.placeid);
          setFieldsData({
            ...response.data.data,
            ["updatekey"]: updatekey,
          });
          getCentre4mPid(response.data.data.placeid);
          getPlace(response.data.data.placeid);
        } else {
          toast.error(response.data.msg);
        }
      } else {
        console.log("error");
        navigate(`/dashboard`);
      }
    });
  };
  const getPlace = (pid) => {
    // getPlaceById({ placeid: onUpdatePlaceId }).then((response) => { // onUpdatePlaceId when retrive full place list first load onUpdatePlaceId='' sec time = 'placeid'
    getPlaceById({ placeid: pid }).then((response) => {
      if (response.status === 200) {
        if (response.data.status === "success") {
          // console.log(response.data.data);
          setPlace(response.data.data);
        } else {
          toast.error(response.data.msg);
        }
      } else {
        navigate(`/dashboard`);
      }
    });
  };
  const getCentre4mPid = (pid) => {
    // console.log("pid", pid);
    getCentre4mPlaceId({ placeid: pid }).then((response) => {
      if (response.status === 200) {
        if (response.data.status === "success") {
          console.log("C", response.data.data);
          setCentre(response.data.data);
        } else {
          toast.error(response.data.msg);
        }
      } else {
        console.log("error");
        navigate(`/dashboard`);
      }
    });
  };
  useEffect(() => {
    if (updatekey) {
      setEditMode(true);
      getUpdationDetails();
    }
  }, [updatekey]);
  //
  return (
    <>
      <div className="app-content pt-3 p-md-3 p-lg-4">
        <div className="container-xl">
          <h1 className="app-page-title">
            {editMode ? "UPDATE USER" : "ADD NEW USER"}
          </h1>
          <div className="row">
            <div className="col-12 app-card-settings app-card pt-3 pb-3">
              <div className="row mb-4">
                <div className="col-md-3 col-12">
                  <label className="form-label">
                    Username <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="uname"
                    value={fieldsData.uname}
                    onChange={updateFieldsData}
                    placeholder="Username"
                    disabled={editMode ? "disabled" : ""}
                  />
                </div>
                <div className="col-md-3 col-12">
                  <label className="form-label">
                    Name <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    value={fieldsData.name}
                    onChange={updateFieldsData}
                    placeholder="Name"
                  />
                </div>
                {!editMode ? (
                  <>
                    <div className="col-md-3 col-12">
                      <label className="form-label">
                        Password{" "}
                        <span style={{ color: "red" }}>*(Min 8 char)</span>
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        name="password"
                        value={fieldsData.password}
                        onChange={updateFieldsData}
                        placeholder="Password"
                      />
                    </div>
                    <div className="col-md-3 col-12">
                      <label className="form-label">
                        Confirm Password <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        name="confirmpwd"
                        value={fieldsData.confirmpwd}
                        onChange={updateFieldsData}
                        placeholder="Confirm Password"
                      />
                    </div>
                  </>
                ) : null}
              </div>
              <div className="row mb-4">
                <div className="col-md-3 col-12">
                  <label className="form-label" htmlFor="">
                    Role <span style={{ color: "red" }}>*</span>
                  </label>
                  <select
                    className="form-select"
                    name="roleid"
                    value={fieldsData.roleid}
                    onChange={updateFieldsData}
                  >
                    <option value="">Select Role</option>
                    {roles.map((val, key) => {
                      return (
                        <>
                          {userData.role <= 2 ? (
                            <option key={key} value={val.id}>
                              {val.name}
                            </option>
                          ) : val.id >= userData.role ? (
                            <option key={key} value={val.id}>
                              {val.name}
                            </option>
                          ) : null}
                        </>
                      );
                    })}
                  </select>
                </div>
                <div className="col-md-3 col-12">
                  <label className="form-label" htmlFor="">
                    Designation <span style={{ color: "red" }}>*</span>
                  </label>
                  <select
                    className="form-select"
                    name="designationid"
                    value={fieldsData.designationid}
                    onChange={updateFieldsData}
                  >
                    <option value="">Select Designation</option>
                    {designation.map((val, key) => {
                      return (
                        <option key={key} value={val.id}>
                          {val.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="col-md-3 col-12">
                  <label className="form-label">Email</label>
                  <input
                    type="text"
                    className="form-control"
                    name="email"
                    value={fieldsData.email}
                    onChange={updateFieldsData}
                    placeholder="Email"
                  />
                </div>
                <div className="col-md-3 col-12">
                  <label className="form-label" htmlFor="">
                    Contact <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="contact"
                    maxLength={10}
                    value={fieldsData.contact}
                    onChange={updateFieldsData}
                    placeholder="Contact"
                  />
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-md-3 col-12">
                  <label className="form-label" htmlFor="">
                    Area
                  </label>
                  <select
                    className="form-select"
                    name="areaid"
                    value={fieldsData.areaid}
                    onChange={updateFieldsData}
                  >
                    <option value="">Select Area</option>
                    {area.map((val, key) => {
                      return (
                        <>
                        {getRole() <= 2 ? (
                          <option key={key} value={val.id}>
                            {val.name}
                          </option>
                        ) : getAreaId() === val.id ? (
                          <option key={key} value={val.id}>
                            {val.name}
                          </option>
                        ) : null}
                      </>
                      );
                    })}
                  </select>
                </div>
                <div className="col-md-3 col-12">
                  <label className="form-label" htmlFor="">
                    Attached Centre
                  </label>
                  <select
                    className="form-select"
                    name="centreid"
                    value={fieldsData.centreid}
                    onChange={updateFieldsData}
                  >
                    <option value="">Select Center</option>
                    {centre.map((val, key) => {
                      return (
                        <option key={key} value={val.id}>
                          {val.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="col-md-3 col-12">
                  <label className="form-label" htmlFor="">
                    Place (C/SC/SP) <span style={{ color: "red" }}>*</span>
                  </label>
                  <select
                    className="form-select"
                    name="placeid"
                    value={fieldsData.placeid}
                    onChange={updateFieldsData}
                  >
                    <option value="">Select Place</option>
                    {place.map((val, key) => {
                      return (
                        <option key={key} value={val.id}>
                          {val.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="col-md-3 col-12">
                  <label className="form-label" htmlFor="">
                    Status <span style={{ color: "red" }}>*</span>
                  </label>
                  <select
                    className="form-select"
                    name="userstatusid"
                    value={fieldsData.userstatusid}
                    onChange={updateFieldsData}
                  >
                    <option value="">Select Status</option>
                    <option value="1">Active</option>
                    <option value="0">Not Active</option>
                  </select>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-md-4 col-12"></div>
                <div className="col-md-4" style={{ float: "right" }}>
                  <button
                    className="btn app-btn-primary"
                    style={{ width: "100%" }}
                    onClick={checkValidation}
                  >
                    {" "}
                    {editMode === true ? "Update User" : "Add New User"}
                  </button>
                </div>
                <div className="col-md-4 col-12"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showDataTable ? <ViewUser myprops={myprops} /> : null}
    </>
  );
}
